import React, {useState} from "react";
import _ from "lodash";
import ProjectController from "../../../api/controller/project-controller";
import conf_prop from "../../../properties/properties";
// import userDefaultImg from "../../assets/images/users/user.png"
const route_path = conf_prop.get("route_path");
export const ProjectPhoto = (props) => {
    const [videoURL, setVideoURL] = useState(false);
    const {project} = props;
    if (!_.isEmpty(project)) {
        if (_.isEmpty(videoURL)) {
            ProjectController .findPhotoById(project.projectId_ENCRYPTED).then(blob => {
                let videoURL = window.URL.createObjectURL(blob);
                setVideoURL(videoURL);

            }).catch(error => {
                console.error(error);

            });
            return (
                <img className="rounded-circle integr8_profile_img" width="100" alt={"user-photo"}
                     src="https://cdn.thinktalentws48.click/image/click_to_add_logo.jpg"/>
            );
        } else {
            return (
                <img className="rounded-circle integr8_profile_img" width="100" alt={"user-photo"}
                     src={videoURL}/>
            );
        }

    } else {
        return (<img className="rounded-circle integr8_profile_img" width="100"
                     src={userDefaultImg} alt={"user-photo"}/>)
    }
};


