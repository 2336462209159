import * as yup from "yup";

export function individualProjectUpdateSchema() {
    return yup.object().shape({
        noOfParticipants: yup.string().required(),
        startDate: yup.date().required(),
        endDate: yup.date().required(),
        projectToolMappers: yup.array().min(1).of(
            yup.object().shape({
                tool: yup.object().shape({
                    toolId: yup.number().integer().positive().required(),
                })
            })
        ).required(),
        solution: yup.object().shape({
            psId: yup.number().integer().positive().required(),
        }).required(),
    });

}