import * as Yup from 'yup';

export function projectStatusSchema() {
    return Yup.object().shape({
        statusContent: Yup.string().trim().required(),
        healthType: Yup.object().shape({
            healthId: Yup.number().integer().positive().required()
        }).required()
    });
}
