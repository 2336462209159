import properties from '../js/properties';
import {getEnvMap} from "./properties-env";


let conf = new Map();

let conf_prop = new Map([...conf, ...properties, ...getEnvMap()]);

conf_prop.set("route_path", `/integr8`);

const oauth_config = {
    endpoint: conf_prop.get("oauth_endpoint"),
    baseUrl: conf_prop.get("oauth_endpoint") + "/api",
    clientId: "next",
    clientSecret: "next",
    redirect_uri: `${conf_prop.get("domainUrl")}${conf_prop.get("route_path")}/login/integr8-authorized`,
    // app_oauth_redirect_uri: `${conf_prop.get("domainUrl")}/login/app-oauth-login-authorized`,
    integr8_oauth_redirect_uri: `${conf_prop.get("domainUrl")}${conf_prop.get("route_path")}/login/integr8-authorized`,
    authorizePath: '/oauth/authorize',
    tokenPath: '/oauth/token',
    revokePath: '/oauth/revoke'
};
conf_prop.set("oauth_config", oauth_config);

// conf_prop.set("port", 3004);
// conf_prop.set("program_manager_endpoint", oauth_config.endpoint + oauth_config.authorizePath + "?response_type=code&client_id=" + oauth_config.clientId + "&redirect_uri=" + oauth_config.redirect_uri + "&scope=email");
// conf_prop.set("app_oauth_endpoint", conf_prop.get("oauthServiceUrl") + oauth_config.authorizePath + "?response_type=code&client_id=" + oauth_config.clientId + "&redirect_uri=" + oauth_config.app_oauth_redirect_uri + "&scope=email");
conf_prop.set("integr8_oauth_endpoint", conf_prop.get("integr8_oauthServiceUrl") + oauth_config.authorizePath + "?response_type=code&client_id=" + oauth_config.clientId + "&redirect_uri=" + oauth_config.integr8_oauth_redirect_uri + "&scope=email");
export default conf_prop;
