import React, {lazy} from "react";
import Loadable from "../layouts/loader/Loadable";
import conf_prop from "../properties/properties";
import {Navigate} from "react-router-dom";


const LoginClientView = Loadable(lazy(() => import('../pages/login/client-login')));

const ClientAuthorizedView = Loadable(lazy(() => import("../pages/login/client-authorized")));

const LogoutView = Loadable(lazy(() => import("../pages/login/logout")));


const route_path = conf_prop.get("route_path");

const LoginRoutes = [


    {
        path: `${route_path}/login/integr8`,
        name: 'LoginClientView',
        exact: true,
        element: <LoginClientView/>
    },

    {
        path: `${route_path}/login/integr8-authorized`,
        name: 'ClientAuthorizedView',
        exact: true,
        element: <ClientAuthorizedView/>
    },
    {
        path: `${route_path}/login/logout`,
        name: 'LogoutView',
        exact: true,
        element: <LogoutView/>,
    },
    {path: '*', element: <Navigate to="/auth/404"/>},
];
export default LoginRoutes;

