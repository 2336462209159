import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import ApiUtils from '../../api/ApiUtils';
import UserInfoController from '../../api/controller/userinfo-controller';
import { useTranslation } from 'react-i18next';
import LoaderSpinner from '../../components/utils/loader-spinner';
// import { NextMaterialTable } from '../../components/material table';
import img2 from '../../assets/images/user.png';
import { PartnerPhoto } from '../common-components/user-photo';
import { useDispatch } from 'react-redux';
import { ToggleMiniSidebar } from '../../store/customizer/CustomizerSlice';
import { NextMaterialTable } from '../../components/material table';


function PmMtFinancePartnerProfile(props) {
  const userInfo = ApiUtils.getLocalStorage('userInfo');
  const role = userInfo.role;
  const { t } = useTranslation();
  const [modal, set_modal] = useState(false);
  const [modal1, set_modal1] = useState(false);
  const [partnerList, set_partnerList] = useState([]);
  const [isLoaded, set_isLoaded] = useState(false);
  const dispatch = useDispatch();


  function toggle() {
    set_modal(modal_prv => !modal_prv);
  }

  function toggle1() {
    set_modal1(modal1_prv => !modal1_prv);
  }

  async function findPartnerProfileList() {
    await UserInfoController.findAll().then(userInfoList => {

      const partnerList = userInfoList.filter(userInfo => userInfo.role.internalName === 'PARTNER');
      set_partnerList(partnerList);
      set_isLoaded(true);
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      set_isLoaded(true);
    });
  }

  const columns = useMemo(() => [
    {
      header: '',
      size: 70,
      id: 'photo',
      enableResizing: false,
      Cell: ({ row }) => {
        const rowData = row.original;

        return (
          <div>
            <span>
              <div className="text-center ps-1">
                {!_.isEmpty(rowData.mimeType) ? <PartnerPhoto userInfo={rowData} /> :
                  <img src={img2} className="rounded-circle" width="43" alt={''} />}
              </div>
            </span>
          </div>
        );
      },

    },
    {
      header: t('common.component.proj.clnt.cont.name'),
      accessorFn: (row) => _.isEmpty(row.name) ? '-' : row.name,
      size: 180,
      enableResizing: false,
      Cell: ({ row }) => {
        const rowData = row.original;
        return (
          (<div className="text_ellipsis" title={rowData.name}>
            {rowData.name}
          </div>)
        );
      },
    },
    {
      header: t('common.component.reset.password.email'),
      size: 240,
      enableResizing: false,
      accessorFn: (row) => _.isEmpty(row.email) ? '-' : row.email,
      Cell: ({ row }) => {
        const rowData = row.original;
        return (
          (<div className="text_ellipsis" title={rowData.email}>
            {rowData.email}
          </div>)
        );
      },

    },
    {
      header: t('integr8.number.text'),
      accessorFn: (row) => _.isEmpty(row.contactNumber) ? '-' : row.contactNumber,
      size: 150,
      enableResizing: false,
    },
    {
      header: t('pm.mt.finance.partner.profile.header.location'),
      size: 180,
      enableResizing: false,
      accessorFn: (row) => {
        const locationDemographic = row.userDemographics.find(x => x.filterName === 'Location');
        const filterValue = locationDemographic ? locationDemographic.filterValue : '-';
        return filterValue;
      },

    },
    {
      header: t('pm.mt.finance.partner.profile.industry'),
      size: 200,
      enableResizing: false,
      accessorFn: (row) => {
        const industryDemographic = row.userDemographics.find(x => x.filterName === 'Industry');
        const filterValue = industryDemographic ? industryDemographic.filterValue : '-';
        return filterValue;
      },

      Cell: ({ row }) => {
        const rowData = row.original;
        const industryDemographic = rowData.userDemographics.find(x => x.filterName === 'Industry');
        const filterValue = industryDemographic ? industryDemographic.filterValue : '-';
        return (
          (<div className="text_ellipsis" title={filterValue}>
            {filterValue}
          </div>)
        );
      },
    },
    {
      header: t('pm.mt.finance.partner.profile.header.language'),
      size: 200,
      enableResizing: false,
      accessorFn: (row) => {
        const languageDemographic = row.userDemographics.find(x => x.filterName === 'Language');
        const filterValue = languageDemographic ? languageDemographic.filterValue : '-';
        return filterValue;
      },
      Cell: ({ row }) => {
        const rowData = row.original;
        const languageDemographic = rowData.userDemographics.find(x => x.filterName === 'Language');
        const filterValue = languageDemographic ? languageDemographic.filterValue : '-';
        return (
          (<div className="text_ellipsis" title={filterValue}>
            {filterValue}
          </div>)
        );
      },

    },
    {
      header: 'Action',
      enableResizing: false,
      id: 'edit',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
      size: 120,
      Cell: ({ row }) => {
        const rowData = row.original;
        return (
          <span>
            {userInfo.role.internalName === 'PM' &&
            <a href={'/integr8/program-manager/partner-single-profile/' + rowData.userId_ENCRYPTED}
               className="next_outline_accent1_btn next_btn_lg">{t('program.manager.partner.profile.view.profile')}</a>}
            {userInfo.role.internalName === 'MT' &&
              <a href={'/integr8/management/partner-single-profile/' + rowData.userId_ENCRYPTED}
                 className="next_outline_accent1_btn next_btn_lg">{t('program.manager.partner.profile.view.profile')}</a>}
            {userInfo.role.internalName === 'FINANCE' &&
              <a href={'/integr8/finance/partner-single-profile/' + rowData.userId_ENCRYPTED}
                 className="next_outline_accent1_btn next_btn_lg">{t('program.manager.partner.profile.view.profile')}</a>}

            {userInfo.role.internalName === 'SALES' &&
              <a href={'/integr8/sales/partner-single-profile/' + rowData.userId_ENCRYPTED}
                 className="next_outline_accent1_btn next_btn_lg">{t('program.manager.partner.profile.view.profile')}</a>}
                                </span>
        );
      },
    },
  ]);


  useEffect(() => {
    dispatch(ToggleMiniSidebar());

    async function fetchData() {
      await findPartnerProfileList();
    }

    fetchData();

  }, []);

  if (!isLoaded) {
    return (<div style={{ marginTop: '15%' }}><LoaderSpinner /></div>);
  } else {

    return (<div>

      <Row>
        <Col md={6} className="ps-0 pe-0"><h4>
          <b>{t('program.manager.partner.profile.heading.partner.profile')}</b></h4></Col>
      </Row>

      <div className="containt_e2e1 table_hover mt-3" style={{ clipPath: 'content-box' }}>
        <NextMaterialTable
          columns={columns}
          data={partnerList}
          // data={_.orderBy(partnerList, ['genDate'], ['desc'])}
          options={{
            enableColumnResizing: true,
            // enableRowSelection: true,
            // onRowSelectionChange: ({ rowSelectionState }) => console.log(rowSelectionState),
            state: {
              showLoadingOverlay: !isLoaded,
              showSkeletons: !isLoaded,
            },


          }}
        />
      </div>

    </div>);
  }
}

export default PmMtFinancePartnerProfile;
