import React, {Component} from 'react';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import {FilePond, registerPlugin} from 'react-filepond';


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// Our app
class UploadFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Set initial files, type 'local' means this is a file
            // that has already been uploaded to the server (see docs)
            files: [],

        };
    }

    handleInit() {

    }

    render() {
        return (
            <div className="UploadFile">

                {/* Pass FilePond properties as attributes */}
                <FilePond ref={ref => this.pond = ref}
                          files={this.props.files}
                    //allowMultiple={true}
                          // acceptedFileTypes={'application/pdf'}
                    // maxFiles={3}
                    // server="/api"
                          oninit={() => this.handleInit()}
                          onupdatefiles={(fileItems) => {
                              // Set current file objects to this.state
                              // this.setState({
                              //     files: fileItems.map(fileItem => fileItem.file)
                              // });
                              const files = fileItems.map(fileItem => fileItem.file);
                              this.props.handleFileState(files)
                          }}>
                </FilePond>

            </div>
        );
    }
}

export default UploadFile
