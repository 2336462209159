import React from "react";
import {Button, Spinner} from "reactstrap";
import PropTypes from 'prop-types';
import {Oval} from "react-loader-spinner";

function ButtonWithLoader(props) {


    const {loading, text, className, disabled} = props;
    if (loading) {
        return (

            <Button type="button" className={`next_btn ${className}`} disabled>
                <div className="next_btn_with_loader">

                    <Oval  color="#ffffff" height={20} width={20} ariaLabel="loading"/>
                    <span className="btnWithLoader m-auto">
                        <span> {text} </span>
                    </span>

                </div>
            </Button>

        )
    } else {
        return (
            <Button disabled={disabled} type="button" className={`next_btn ${className}`} onClick={props.onClick}>
                <span> {text} </span>
            </Button>
        )
    }


}

ButtonWithLoader.propTypes = {
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    text: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired

}


export default ButtonWithLoader;