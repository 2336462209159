import React, {useEffect, useMemo, useState} from 'react';
import {Col, Row,} from 'reactstrap';
import {useTranslation} from "react-i18next";
import ApiUtils from "../../api/ApiUtils";
import StandardDocumentController from "../../api/controller/standardDocument-controller";
import Moment from "react-moment";
import Loader from "../../layouts/loader/Loader";
import DataTableView from "../../components/data-table/data-table-view";
import _ from "lodash";
import conf_prop from "../../properties/properties";


function DocumentRepository() {

    let userInfo = ApiUtils.getLocalStorage("userInfo")
    const {t} = useTranslation();

    const [isLoaded, set_isLoaded] = useState(false);
    const [document, set_document] = useState({});
    const [documentList, set_documentList] = useState([]);


    async function findDocument() {
        await StandardDocumentController.findAll().then(documentList => {
            set_documentList(documentList);
        }).catch(error => console.log(error));
    }

    useEffect(() => {
        async function fetchData() {
            await findDocument();
            set_isLoaded(true);
        }

        fetchData();

    }, [])

    const columns = useMemo(() => [{
        name: t('integr8.document.text'),
        selector: row => row.docOriginalName,
        filter: (row, value) => row.docOriginalName.toString().toLowerCase().includes(value.toLowerCase()),
        sortable: true,

    }, {
        name: t('integr8.updated.on.text'),
        cell: row => (<Moment local titleFormat="D MMM YYYY" format="D MMM YYYY" withTitle>
            {row.genDate}
        </Moment>)
    }, {
        name: t('integr8.download.btn.text'),
        sortable: true,
        cell: row => (<a className=""
            style={{borderRadius: '4px', fontSize: '12px'}}
            href={conf_prop.get("integr8ServiceUrl") + "/rest/standarddocument/document-repository-download/" + row.sdId_ENCRYPTED}
        ><i className="ti-download me-1" style={{color: "#37A4FE"}}/>{row.docOriginalName} </a>)
    }]);


    if (!isLoaded) {
        return (<div style={{marginTop: '15%'}}><Loader/></div>)
    } else {

        return (

            <div>
                <Row>
                    <Col md={6} className="ps-0 pe-0">
                        <h4><b>{t("integr8.document.repository.text")}</b></h4>
                    </Col>
                </Row>
                <div className="feedback_table containt_e2e mt-3">
                    <DataTableView
                        title={"Upload Document"}
                        columns={columns}
                        options={{search: true}}
                        data={_.orderBy(documentList, ['genDate'], ['asc'])}/>
                </div>
            </div>);
    }
}

export default DocumentRepository;