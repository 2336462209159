import React, {useEffect, useState} from 'react';
import {Col,} from 'reactstrap';
import {useTranslation} from "react-i18next";
import LoaderSpinner from "../../../components/utils/loader-spinner";
import ProjectController from "../../../api/controller/project-controller";
import {ButtonWithLoader, toastify} from "../../../components/utils";
import SunEditor from "suneditor-react";
import update from "immutability-helper";
import {basicSunEditorListTableOptions} from '../../../config/suneditor-config';
import _ from "lodash";

function PmMtPartnerProjectSummaryTab(props) {
    const {userInfo, findProjectById} = props;

    const {t} = useTranslation();
    const [editModal, setEditModal] = useState(false);
    const [isUpLoaded, set_isUpLoaded] = useState(false);
    const [newPartnerModal, se_newPartnerModal] = useState(false);
    const [isLoaded, set_isLoaded] = useState(false);
    const [project, set_project] = useState({summary: '', ...props.project});


    function onProjectSummaryChange(content) {
        set_project(summary_prev => update(summary_prev, {summary: {$set: content}}));

    }

    function onSave() {
        set_isUpLoaded(true);

        ProjectController.insertSummary(project).then(result => {
            set_project(summary_prev => update(summary_prev, {summry: {$set: ""}}));
            set_isUpLoaded(false);
            toastify("success", t("common.component.project.summary.project.added"), t("common.component.project.summary.project.added"));
        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            findProjectById();
            set_isUpLoaded(false)
        });

    }


    useEffect(() => {
        set_isLoaded(false);

        async function fetchData() {
            set_isLoaded(true);
        }

        fetchData();

    }, []);


    if (!isLoaded) {
        return (<div style={{marginTop: '15%'}}><LoaderSpinner/></div>);
    } else {
        return (<div>
            {userInfo.role.internalName === "PM" ? (<div>
                <div>
                    {project.projectStatus === "Y" ? (<Col className="mt-4" md={12}>
                        <div className="">
                            <SunEditor
                                placeholder={t(`common.component.proj.clnt.cont.type.here`)}
                                name="pageDesc"
                                setOptions={{
                                    mode: 'classic',
                                    height: '150px',
                                    width: 'auto',
                                    resizingBar: false,
                                    showPathLabel: false,
                                    'buttonList': [basicSunEditorListTableOptions],
                                }}
                                autoFocus={false}
                                // hideToolbar={true}
                                //setContents={project.summary}
                                defaultValue={project.summary}
                                onChange={onProjectSummaryChange}
                                setDefaultStyle="font-family: Poppins , sans-serif; font-size: 14px;"
                            />
                        </div>

                        <div className="text-end mt-3">
                            <ButtonWithLoader
                                className=" next_btn next_btn_lg"
                                loading={isUpLoaded}
                                disabled={_.isEmpty(project.summary)}
                                onClick={onSave}
                                text={t("integr8.common.save.btn")}
                            >
                            </ButtonWithLoader>

                        </div>

                    </Col>) : (<Col className="mt-4" md={12}>
                        <div>
                            <Col className="mt-4" md={12}>
                                <div className="mt-3">
                                    <div className={'mb-3'}>
                                        <strong>{t("common.component.project.summary.about.project")}</strong>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: project.summary}}/>
                                </div>
                            </Col>
                        </div>
                    </Col>)}
                </div>

            </div>) : (<div>
                <Col className="mt-4" md={12}>
                    <div className="mt-3">
                        <div className={'mb-3'}><strong>{t("common.component.project.summary.about.project")}</strong>
                        </div>
                        {_.isEmpty(project.summary) ? t("common.component.project.summary.no.summary") : (
                            <div dangerouslySetInnerHTML={{__html: project.summary}}/>)}
                    </div>
                </Col>
            </div>)}

        </div>);
    }
}


export default PmMtPartnerProjectSummaryTab;