import * as Yup from 'yup';

export const addDescriptionModalBtnSchema = Yup.object().shape({
    description: Yup.string().trim().required('Description is required'),
    unit: Yup.string().trim().required('Unit is required'),
    fee: Yup.number().required('Fee is required'),
    serviceType: Yup.object().shape({
        stId: Yup.number().required('Service Type ID is required')
    })


});

export const updateDescriptionModalBtnSchema = Yup.object().shape({
    description: Yup.string().trim().required('Description is required'),
    unit: Yup.string().trim().required('Unit is required'),
    fee: Yup.number().required('Fee is required'),
    serviceType: Yup.object().shape({
        stId: Yup.number().required('Service Type ID is required')
    })


});