import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NextMaterialTable } from '../../../components/material table';
import _ from 'lodash';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoaderSpinner from '../../../components/utils/loader-spinner';

//convert class to function

function ProjectProfitabilityTab({ projectProfitabilityData }) {
  const { t } = useTranslation();
  const [isLoaded, set_isLoaded] = useState(false);
  const [instructionModal, setInstructionModal] = useState(false);

  function instructionToggle() {
    setInstructionModal((instructionToggle_prev) => {
      return !instructionToggle_prev;
    });
  }

  const columns = useMemo(() => [{
    header: t('mt.sales.pipeline.tab3.column.project.text'),
    id: 'projectName',
    accessorFn: (row) => row.projectName,
    size: 180,
    enableResizing: false,
    Cell: ({ row }) => {
      const rowData = row.original;
      return (<div className="text_ellipsis" title={rowData.projectName}>
        {!_.isEmpty(rowData.projectName) ? rowData.projectName : '-'}
      </div>);
    },
  }, {
    header: t('mt.sales.pipeline.tab3.column.client.name.text'),
    id: 'clientName',
    accessorFn: (row) => !_.isEmpty(row.clientName) ? row.clientName : '-',
    size: 180,
    enableResizing: false,
    Cell: ({ row }) => {
      const rowData = row.original;
      return (<div className="text_ellipsis" title={rowData.clientName}>
        {!_.isEmpty(rowData.clientName) ? rowData.clientName : '-'}
      </div>);
    },
  },

    {
      header: t('mt.sales.pipeline.tab3.column.sales.member.text'),
      id: 'saleMember',
      accessorFn: (row) => !_.isEmpty(row.saleMember) ? row.saleMember : '-',
      size: 180,
      enableResizing: false,
      Cell: ({ row }) => {
        const rowData = row.original;
        return (<div className="text_ellipsis" title={rowData.saleMember}>
          {!_.isEmpty(rowData.saleMember) ? rowData.saleMember : '-'}
        </div>);
      },
    }, {
      header: t('mt.sales.pipeline.tab3.column.project.manager.text'),
      id: 'projectManager',
      accessorFn: (row) => !_.isEmpty(row.projectManager) ? row.projectManager : '-',
      size: 180,
      enableResizing: false,
      Cell: ({ row }) => {
        const rowData = row.original;
        return (<div className="text_ellipsis" title={rowData.projectManager}>
          {!_.isEmpty(rowData.projectManager) ? rowData.projectManager : '-'}
        </div>);
      },
    }, {
      header: t('mt.sales.pipeline.tab3.column.industry.text'),
      id: 'industryName',
      accessorFn: (row) => row.industryName,
      size: 180,
      enableResizing: false,
      Cell: ({ row }) => {
        const rowData = row.original;
        return (<div className="text_ellipsis" title={rowData.industryName}>
          {!_.isEmpty(rowData.industryName) ? rowData.industryName : '-'}
        </div>);
      },
    }, {
      header: t('mt.sales.pipeline.tab3.column.solution.text'),
      id: 'solutionName',
      accessorFn: (row) => row.solutionName,
      size: 180,
      enableResizing: false,
      Cell: ({ row }) => {
        const rowData = row.original;
        return (<div className="text_ellipsis" title={rowData.solutionName}>
          {!_.isEmpty(rowData.solutionName) ? rowData.solutionName : '-'}
        </div>);
      },
    }, {
      header: t('mt.sales.pipeline.tab3.column.est.gross.margin.text'),
      id: 'estimatedGrossMargin',
      accessorFn: (row) => row.estimatedGrossMargin,
      size: 180,
      enableResizing: false,
    }, {
      header: t('mt.sales.pipeline.tab3.column.actual.gross.margin.text'),
      id: 'actualGrossMargin',
      accessorFn: (row) => row.actualGrossMargin,
      size: 180,
      enableResizing: false,
    }, {
      header: t('mt.sales.pipeline.tab3.column.project.value.text'),
      id: 'projectValue',
      accessorFn: (row) => _.isNull(row.projectValue) ? 0 : row.projectValue.toLocaleString('en-IN'),
      size: 180,
      enableResizing: false,
    }, {
      header: t('mt.sales.pipeline.tab3.column.total.est.revenue.text'),
      id: 'totalEstimatedRevenue',
      accessorFn: (row) => row.totalEstimatedRevenue.toLocaleString('en-IN'),
      size: 180,
      enableResizing: false,
    }, {
      header: t('mt.sales.pipeline.tab3.column.total.est.cost.text'),
      id: 'totalEstimatedCost',
      accessorFn: (row) => row.totalEstimatedCost.toLocaleString('en-IN'),
      size: 180,
      enableResizing: false,
    }, {
      header: t('mt.sales.pipeline.tab3.column.total.invoiced.amt.text'),
      id: 'totalInvoicedAmount',
      accessorFn: (row) => row.totalInvoicedAmount.toLocaleString('en-IN'),
      size: 180,
      enableResizing: false,
    }, {
      header: t('mt.sales.pipeline.tab3.column.amt.paid.by.client.text'),
      id: 'totalInvoicedAmountPaidByClient',
      accessorFn: (row) => row.totalInvoicedAmountPaidByClient.toLocaleString('en-IN'),
      size: 180,
      enableResizing: false,
    }, {
      header: t('mt.sales.pipeline.tab3.column.partner.invoiced.amt.text'),
      id: 'partnerInvoicedAmount',
      accessorFn: (row) => _.isNull(row.partnerInvoicedAmount) ? 0 : row.partnerInvoicedAmount.toLocaleString('en-IN'),
      size: 180,
      enableResizing: false,
    },

  ]);

  useEffect(() => {
    async function fetchData() {

      set_isLoaded(true);
    }

    fetchData();
  }, []);


  // const columns = [{
  //     name: t("mt.sales.pipeline.tab3.column.client.name.text"), sortable: true, // maxWidth: "20%",
  //     selector: rowData => rowData.clientName, // filter: (rowData, value) => rowData.projectName.toString().toLowerCase().includes(value.toLowerCase()),
  // },
  //
  //     {
  //         name: t("mt.sales.pipeline.tab3.column.project.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.projectName, // filter: (rowData, value) => rowData.clientName.toString().toLowerCase().includes(value.toLowerCase()),
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.sales.member.text"), sortable: true, // maxWidth: "7%",
  //         selector: rowData => rowData.saleMember, cell: rowData => <span>
  //                 {!_.isEmpty(rowData.saleMember) ? rowData.saleMember : "-"}
  //             </span>,
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.project.manager.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.projectManager, cell: rowData => <span>
  //                 {!_.isEmpty(rowData.projectManager) ? rowData.projectManager : "-"}
  //             </span>,
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.industry.text"), sortable: true, // maxWidth: "19%",
  //         //center: true,
  //         selector: rowData => rowData.industryName, cell: rowData => <span>
  //                 {!_.isEmpty(rowData.industryName) ? rowData.industryName : "-"}
  //             </span>,
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.solution.text"), sortable: true, // maxWidth: "7%",
  //         selector: rowData => rowData.solutionName, cell: rowData => <span>
  //                 {!_.isEmpty(rowData.solutionName) ? rowData.solutionName : "-"}
  //             </span>,
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.project.value.text"), sortable: true, // maxWidth: "19%",
  //         //center: true,
  //         selector: rowData => rowData.projectValue, cell: rowData => <span>
  //                 {!_.isNull(rowData.projectValue) ? rowData.projectValue.toLocaleString('en-IN') : "0"}
  //             </span>,
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.total.est.revenue.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.totalEstimatedRevenue, cell: rowData => <span>
  //             {rowData.totalEstimatedRevenue.toLocaleString('en-IN')}
  //         </span>
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.total.est.cost.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.totalEstimatedCost, cell: rowData => <span>
  //             {rowData.totalEstimatedCost.toLocaleString('en-IN')}
  //         </span>
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.total.invoiced.amt.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.totalInvoicedAmount, cell: rowData => <span>
  //             {rowData.totalInvoicedAmount.toLocaleString('en-IN')}
  //         </span>
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.amt.paid.by.client.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.totalInvoicedAmountPaidByClient, cell: rowData => <span>
  //             {rowData.totalInvoicedAmountPaidByClient.toLocaleString('en-IN')}
  //         </span>
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.partner.invoiced.amt.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.partnerInvoicedAmount, cell: rowData => <span>
  //             {!_.isNull(rowData.partnerInvoicedAmount) ? rowData.partnerInvoicedAmount.toLocaleString('en-IN') : '0'}
  //         </span>
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.est.gross.margin.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.estimatedGrossMargin,
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab3.column.actual.gross.margin.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.actualGrossMargin,
  //
  //     }];

  if (!isLoaded) {


    return (<Card className="text-center">
      <CardBody>
        <LoaderSpinner />
      </CardBody>
    </Card>);
  } else {

    return (<>
      <div className="ms-2" style={{ fontSize: '13px' }}>
          <span style={{ color: '#333' }}>
    {t(`integr8.dashboard.all.the.data.represented.instruction`)}
    <br />
            {/*<i className="fa fa-info-circle" aria-hidden="true" style={{ color: '#007bff', marginRight: '8px' }}></i>*/}
            <a href="#" onClick={instructionToggle} style={{ textDecoration: 'underline', color: '#007bff' }}>
      {t(`integr8.dashboard.click.here.to.learn.instruction`)}
    </a>
  </span>
      </div>
      <div className="table_hover">
        <NextMaterialTable
          columns={columns}
          data={projectProfitabilityData}
          options={{
            enableColumnResizing: true, initialState: {
              columnOrder: ['projectName', 'clientName', 'saleMember', 'projectManager', 'industryName', 'solutionName', 'estimatedGrossMargin', 'actualGrossMargin', 'projectValue', 'totalEstimatedRevenue', 'totalEstimatedCost', 'totalInvoicedAmount', 'totalInvoicedAmountPaidByClient', 'partnerInvoicedAmount', 'partnerInvoicedAmount'],
              columnVisibility: {
                industryName: false,
                solutionName: false,
                saleMember: false,
                totalEstimatedCost: false,
                totalEstimatedRevenue: false,
              },
            }, // enableRowSelection: true,
            enableHiding: true, // onRowSelectionChange: ({ rowSelectionState }) => console.log(rowSelectionState),
            state: {
              showLoadingOverlay: !isLoaded, showSkeletons: !isLoaded,
            },
          }}
        />
      </div>
      {/*==========================================Instruction Modal=================================================*/}

      <Modal
        isOpen={instructionModal}
        toggle={instructionToggle}
        className="modal-lg"
        backdrop={'static'}
      >
        <ModalHeader toggle={instructionToggle}>{t(`integr8.project.profitability.modal.heading`)}</ModalHeader>
        <ModalBody>
          <div>
            <div className={'instruction-body'}>
              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title1`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content1`)}</span>
              </section>

              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title2`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content2`)}</span>
              </section>

              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title3`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content3`)}</span>
              </section>

              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title4`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content4`)}</span>
              </section>

              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title5`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content5`)}</span>
              </section>

              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title6`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content6`)}</span>
              </section>

              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title7`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content7`)}</span><br />

                <div className={'mt-3 mb-3'} style={{ color: '#555', fontFamily: 'Cambria Math' }}>
                <span>Estimated Gross Margin (%) =
                  <div style={{ display: 'inline-block' }}><math display={'block'}>
                  <mfrac>
                    <mrow>
                      <mi>(Total Estimated Revenue</mi>
                      <mo>-</mo>
                      <mi>Total Estimated Cost)</mi>
                    </mrow>
                    <mrow>
                      <mi>(Total
                Estimated Revenue)</mi>
                    </mrow>
                  </mfrac>
                </math></div>
                × 100%
                </span>
                </div>
              </section>

              <section className={'instruction-section'}>
                <h6
                  className={'instruction-title'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.title8`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content8`)}</span><br />

                <div className={' mt-3 mb-3'} style={{ color: '#555', fontFamily: 'Cambria Math' }}>
                <span>Actual Gross Margin (%) =
                  <div style={{ display: 'inline-block' }}><math display={'block'}>
                  <mfrac>
                    <mrow>
                      <mi>(Total Invoiced Amount</mi>
                      <mo>-</mo>
                      <mi>Partner Invoiced Amount)</mi>
                    </mrow>
                    <mrow>
                      <mi>Total Invoiced Amount</mi>
                    </mrow>
                  </mfrac>
                </math></div>
                × 100%
                </span>
                </div>

              </section>
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
          <Button className="integr8_cancel_btn"
                  onClick={instructionToggle}>{t(`integr8.close.btn.text`)}</Button>

        </ModalFooter>

      </Modal>

    </>);

  }
}

export default ProjectProfitabilityTab;
