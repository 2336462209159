import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

var ProjectInvoiceController = {


    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinvoice/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByUserInfo: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinvoice/userinfo/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (piId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinvoice/id/${piId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByProject: function (projectId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinvoice/project/${projectId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    insertProjectInvoice: function (projectInvoice) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinvoice/file/insert`, {
            method: "post",

            body: projectInvoice,

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                //  'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
    updateProjectInvoiceWithFile: function (projectInvoice) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinvoice/file/update`, {
            method: "post",

            body: projectInvoice,

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                //  'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateProjectInvoice: function (projectInvoice) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinvoice/update`, {
            method: "post",

            body: JSON.stringify(projectInvoice),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateProjectInvoicePaymentStatus: function (projectInvoice) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinvoice/finance/invoice/paid`, {
            method: "post",

            body: JSON.stringify(projectInvoice),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
};

export default ProjectInvoiceController;
