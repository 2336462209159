export const basicSunEditorOptions = ["bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
    "fontColor",
    "hiliteColor",
    "textStyle",
    "removeFormat",
    "outdent",
    "indent",
    "align",
    "horizontalRule",
    "list",
    "lineHeight",
    "link",
     "codeView",
];
export const basicSunEditorWithMediaOptions = ["bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
    "fontColor",
    "hiliteColor",
    "textStyle",
    "removeFormat",
    "outdent",
    "indent",
    "align",
    "horizontalRule",
    "list",
    "lineHeight",
    "table",
    "link",
    "image",
    "video",
    "audio",
    "fullScreen",
    "showBlocks",
    "codeView",];


export const basicSunEditorOptionsForEmail = ["bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
    "fontColor",
    "hiliteColor",
    "textStyle",
    "removeFormat",
    "outdent",
    "indent",
    "align",
    "horizontalRule",
    "list",
    "lineHeight",
    "codeView",
];


export const basicSunEditorWithoutVideoOptions = [
    "fontSize",
    "formatBlock",
    "bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
    "fontColor",
    "hiliteColor",
    "textStyle",
    "removeFormat",
    "outdent",
    "indent",
    "align",
    "horizontalRule",
    "list",
    "lineHeight",
    "table",
    "fullScreen",
    "showBlocks",
    "link",
    "codeView"
    ,];

export const basicSunEditorOptionsWithoutLink = ["bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
    "fontColor",
    "hiliteColor",
    "textStyle",
    "removeFormat",
    "outdent",
    "indent",
    "align",
    "horizontalRule",
    "list",
    "lineHeight",
     "codeView",
];

export const basicSunEditorTableOptions = ["undo",
    "redo", "bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
    "removeFormat",
    "outdent",
    "indent",
    "table",
    "fullScreen",
    "showBlocks",
    "codeView",
    "preview",
    "print",
];


export const basicSunEditorListTableOptions = ["bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
    "fontColor",
    "hiliteColor",
    "textStyle",
    "removeFormat",
    "outdent",
    "indent",
    "align",
    "horizontalRule",
    "list",
    "lineHeight",
    "table",
    "link",
     "codeView",
];

export const basicSunEditorImgListTableOptions = ["bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
    "fontColor",
    "hiliteColor",
    "textStyle",
    "removeFormat",
    "outdent",
    "indent",
    "align",
    "horizontalRule",
    "list",
    "lineHeight",
    "table",
    "link",
    "image",
     "codeView",
];
export const basicSunEditorTableOptionsWithList = ["undo",
    "redo", "bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
    "removeFormat",
    "outdent",
    "indent",
    "table",
    "fullScreen",
    "showBlocks",
    "codeView",
    "preview",
    "list"
];