import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FilterComponent, TableExtendedFilter } from './component';
import { filterData, filterExtendedUtil, NextDataTable } from '@devopsthink/next-data-table-component-nextjs';
import { Col, Row } from 'reactstrap';


function DataTableView(props) {
  const { columns, data, options, selectedUserInfos } = props;

  const [filterText, set_filterText] = useState('');
  const [filterOptions, set_filterOptions] = useState([]);
  const [resetPaginationToggle, set_resetPaginationToggle] = useState([]);
  const { t } = useTranslation();

  function handleClear() {
    if (filterText) {
      set_resetPaginationToggle(!resetPaginationToggle);
      set_filterText('');
    }
  }

  function setFilterText(filterText) {
    set_filterText(filterText);
  }

  function setResetPaginationToggle(resetPaginationToggle) {
    setResetPaginationToggle(resetPaginationToggle);

  }

  let filteredData = filterData(columns, data, filterText);
  if (options.extendedSearch) {
    filteredData = filterExtendedUtil(filterOptions, filteredData);
  }


  return (
    <div>

      <Row className={'mb-3'}>
        <Col md={4}>
          {!_.isEmpty(selectedUserInfos) && (
            <div className={'mt-3 '}
                 style={{ paddingLeft: '32px' }}>{selectedUserInfos.length === 0 ? (
              <span></span>
            ) : (
              <small
                className={'tw-text-small'}>{selectedUserInfos.length} {t('admin.user.view.item.selected.text')}</small>
            )}</div>
          )}
        </Col>
        <Col md={8}>
          <div className={'d-flex justify-content-end '} style={{ paddingRight: '25px' }}>
            {options.extendedSearch && (
              <div className={' d-flex  '} style={{ width: '25%' }}>
                <div className={'mr-2 me-2 mt-2 text-end text-right'} style={{ width: '30%' }}>Filter
                  by:
                </div>
                <div style={{ width: '70%' }}>
                  <TableExtendedFilter columns={columns} data={filteredData}
                                       setFilterOptions={x => set_filterOptions(x)} />
                </div>
              </div>
            )}
            {options.search && (
              <div style={{ width: '25%' }} className={'ml-3 ms-3'}>
                <FilterComponent onFilter={e => setFilterText(e.target.value)}
                                 onClear={handleClear}
                                 filterText={filterText} />
              </div>
            )}
          </div>

        </Col>
      </Row>


      <NextDataTable columns={columns} data={filteredData}
                     options={options} setResetPaginationToggle={setResetPaginationToggle} />


    </div>
  );
}

DataTableView.propTypes = {
  options: PropTypes.object,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  extendedSearchGrid: PropTypes.number,
  basicSearchGrid: PropTypes.number,

};

export default DataTableView;