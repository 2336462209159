import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import Select from 'react-select';
import SowDocumentTrackController from '../../api/controller/sowdocumenttrack-controller';
import UserInfoController from '../../api/controller/userinfo-controller';
import ProjectSowController from '../../api/controller/projectsow-controller';
import ServiceTypeController from '../../api/controller/servicetype-controller';
import AddDescriptionModal from '../../views/pm-module/individual-project/add-descriptions-modal';
import {ButtonWithLoader, toastify} from '../../components/utils';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Moment from 'react-moment';
import _ from 'lodash';
import ApiUtils from '../../api/ApiUtils';
import img1 from '../../assets/images/users/project-value.png';
import img2 from '../../assets/images/users/projected-cost.png';
import img3 from '../../assets/images/users/amount-invoiced.png';
import img4 from '../../assets/images/users/paidbyclient.png';
import ProjectController from '../../api/controller/project-controller';


function PmMtSalesSowIssue() {

    let userInfo = ApiUtils.getLocalStorage('userInfo');
    const location = useLocation();
    const {hash} = location;
    const navigate = useNavigate();
    const query_params = new URLSearchParams(location.search);
    const extraParam = query_params.get('extraParam');
    const path_params = useParams();
    const {psowId_ENCRYPTED} = path_params;
    const {t} = useTranslation();
    const [isLoaded, set_isLoaded] = useState(false);
    const [projectSow, set_projectSow] = useState({});
    const [project, set_project] = useState({});
    const [projectSowList, set_projectSowList] = useState([]);
    const [projectCode, set_projectCode] = useState('');
    const [partnerName, set_partnerName] = useState('');
    const [msaIssuedDate, set_msaIssuedDate] = useState([]);
    const [sowNumber, set_sowNumber] = useState('');
    const [sowDocumentTrackList, set_sowDocumentTrackList] = useState([]);
    const [mtMemberList, set_mtMemberList] = useState([]);
    const [mtMember, set_mtMember] = useState({});
    const [newApprovalModal, set_toggleApprovalModal] = useState(false);
    const [isUpLoaded, set_isUpLoaded] = useState(false);
    const [error, set_error] = useState(null);
    const [projectId_ENCRYPTED, set_projectId_ENCRYPTED] = useState('');
    const [serviceTypeList, set_serviceTypeList] = useState([]);
    const [rows, set_rows] = useState([]);
    const [psowId, set_psowId] = useState('');
    const [loading, setLoading] = useState(false);
    const [btnDisabled, set_btnDisabled] = useState(true);
    const [projectDetailsSowModal, set_projectDetailsSowModal] = useState(false);
    const [sowAcceptModal, set_sowAcceptModal] = useState(false);
    const [sowRejectModal, set_sowRejectModal] = useState(false);


    function toggleProjectDetailsSowModal() {
        {
            !_.isEmpty(projectSow) && findProjectById();
        }

        set_projectDetailsSowModal((projectDetailsSowModalPrev) => {
            return !projectDetailsSowModalPrev;
        });
    }

    function toggleMtSowAcceptModal() {
        set_sowAcceptModal((sowAcceptModalPrev) => {
            return !sowAcceptModalPrev;
        });
    }

    function toggleMtSowRejectModal() {
        set_sowRejectModal((sowRejectModalPrev) => {
            return !sowRejectModalPrev;
        });
    }

    function toggleApprovalModal() {
        set_toggleApprovalModal((toggleApprovalModalPrev) => {
            return !toggleApprovalModalPrev;
        });
        set_btnDisabled(true);
    }


    async function findSowDocumentTrackList() {
        await SowDocumentTrackController.findByProjectSow(psowId_ENCRYPTED).then(sowDocumentTracks => {
            set_sowDocumentTrackList(sowDocumentTracks);
        }).catch(error => {
            set_isLoaded(true);
        });
    }

    async function findMTMemberList() {
        await UserInfoController.findAll().then(userInfoList => {

            const mtList = userInfoList.filter(userInfo => userInfo.role.internalName === 'MT');

            const mtMemberList = mtList.map(mt => ({
                ...mt, label: mt.name, value: mt.userId,
            }));
            set_mtMemberList(mtMemberList);

        }).catch(error => {
            set_isLoaded(true);

        });
    }

    function onMtMemberSelect(selectedMtMember) {
        set_mtMember(selectedMtMember);
        set_btnDisabled(false);
    }

    async function findByProjectSow() {
        await ProjectSowController.findById(psowId_ENCRYPTED)
            .then(projectSow => {

                const projectCode = 'PC' + projectSow.project.projectCode;
                const projectId_ENCRYPTED = projectSow.project.projectId_ENCRYPTED;
                const msaIssuedDate = projectSow.projectAdminPartner.userInfo.userInfoDocuments.filter(userInfoDocument => userInfoDocument.docType.docTypeName === 'MSA');

                set_projectCode(projectCode);
                set_sowNumber('SOW' + projectSow.psowId);
                set_projectSow(projectSow);
                set_msaIssuedDate(msaIssuedDate);
            }).catch(error => {
                console.error(error);
            });
    }

    async function findServiceTypeList() {
        await ServiceTypeController.findAll().then(result => {
            const serviceTypeList = result.map((serviceType) => {
                serviceType.label = serviceType.name;
                serviceType.value = serviceType.stId;
                return serviceType;
            });

            set_serviceTypeList(serviceTypeList);
        }).catch(error => {
            console.error(error);
        });
    }

    function updateSowStatusByPm() {
        set_isUpLoaded(true);
        const updateProjectSow = {
            'psowId': projectSow.psowId,
            'project': {'projectId': projectSow.project.projectId},
            'projectAdminMt': {'userInfo': {'userId': mtMember.userId}},
        };

        if (!_.isEmpty(mtMember)) {
            ProjectSowController.updateProjectSow(updateProjectSow).then(result => {
                toastify('success', t('program.manager.issue.sow.for.approval'), t('program.manager.issue.sow.for.approval'));
                // window.location = `${conf_prop.get("domainUrl")}/integr8/program-manager/individual-project/${projectSow.project.projectId_ENCRYPTED}`
                toggleApprovalModal();
                findByProjectSow();
                findSowDocumentTrackList();
                set_isUpLoaded(false);
                // if (!_.isNull(extraParam)) {
                //     window.location.href = `/integr8/management/individual-project/${projectSow.project.projectId_ENCRYPTED}#2`;
                // } else {
                //     window.location.href = `/integr8/management/approval#1`;
                // }
            }).catch(error => {
                set_isUpLoaded(false);
                console.error(error);
            });
        } else {
            toastify('error', t('program.manager.issue.sow.select.mt.member'), t('program.manager.issue.sow.select.mt.member'));
        }

    }

    function approvedSowByMt() {

        set_isUpLoaded(true);
        const updateProjectSow = {
            'psowId': projectSow.psowId,
            'project': {'projectId': projectSow.project.projectId},
            'projectAdminMt': {'userInfo': {'userId': userInfo.userId}},
        };

        ProjectSowController.approvedProjectSowByMt(updateProjectSow).then(result => {
            toastify('success', t('program.manager.issue.sow.approved'), t('program.manager.issue.sow.approved'));
            // window.location = `${conf_prop.get("domainUrl")}/integr8/management/individual-project/${projectSow.project.projectId_ENCRYPTED}`
            set_isUpLoaded(false);
            toggleMtSowAcceptModal();
            findByProjectSow();
            findSowDocumentTrackList();
            set_isLoaded(true);
            if (!_.isNull(extraParam)) {
                window.location.href = `/integr8/management/individual-project/${projectSow.project.projectId_ENCRYPTED}#2`;
            } else {
                window.location.href = `/integr8/management/approval#1`;
            }

        }).catch(error => {
            set_isUpLoaded(false);
            console.error(error);
        });
    }

    function rejectedSowByMt() {

        set_isUpLoaded(true);
        const updateProjectSow = {
            'psowId': projectSow.psowId,
            'project': {'projectId': projectSow.project.projectId},
            'projectAdminMt': {'userInfo': {'userId': userInfo.userId}},
        };

        ProjectSowController.rejectedProjectSowByMt(updateProjectSow).then(result => {
            toastify('error', t('program.manager.issue.sow.rejected'), t('program.manager.issue.sow.rejected'));
            // window.location = `${conf_prop.get("domainUrl")}/integr8/management/individual-project/${projectSow.project.projectId_ENCRYPTED}`
            set_isUpLoaded(false);
            toggleMtSowRejectModal();
            findByProjectSow();
            findSowDocumentTrackList();
            set_isLoaded(true);
            if (!_.isNull(extraParam)) {
                window.location.href = `/integr8/management/individual-project/${projectSow.project.projectId_ENCRYPTED}#2`;
            } else {
                window.location.href = `/integr8/management/approval#1`;
            }
        }).catch(error => {
            set_isUpLoaded(false);
            console.error(error);
        });
    }

    async function findProjectById() {

        await ProjectController.findById(projectSow.project.projectId_ENCRYPTED)
            .then(project => {
                set_project(project);
            }).catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        set_isLoaded(false);

        async function fetchData() {
            await findByProjectSow();
            await findSowDocumentTrackList();
            await findServiceTypeList();
            await findMTMemberList();
            set_isLoaded(true);
        }

        fetchData();

    }, []);


    return (<div>
        {/*<Row>
            <Col md={12}>
                <div className={'font-14'}>
            <span className="me-1">
              <i className="fas fa-angle-left"></i>
            </span>
                    {userInfo.role.internalName === "PM" ? (<Link
                        to={`${conf_prop.get("route_path")}/program-manager/individual-project/${!_.isEmpty(projectSow) && projectSow.project.projectId_ENCRYPTED}#2`}
                        className="text_next_color"
                    >
                        {t('integr8.back.btn')}
                    </Link>) : (<Link
                        to={`${conf_prop.get("route_path")}/management/individual-project/${!_.isEmpty(projectSow) && projectSow.project.projectId_ENCRYPTED}#2`}
                        className="text_next_color"
                    >
                        {t('integr8.back.btn')}
                    </Link>)}

                </div>
            </Col>
        </Row>*/}

        <Row className="mb-3 mt-2">
            <Col md={6}><h4><b>{t('program.manager.issue.sow.header.issue.sow')}</b></h4></Col>
            <Col md={6}>
                <div className="text-end">
                    {(userInfo.role.internalName === 'PM' || userInfo.role.internalName === 'SALES' || userInfo.role.internalName === 'MT') ? (
                        <Button className="next_outline_accent1_btn next_btn_lg me-2"
                                onClick={toggleProjectDetailsSowModal}
                        >{t('integr8.sow.project.details.btn')}
                        </Button>) : (<span></span>)}

                    <span>
                        {userInfo.role.internalName === 'PM' ? (<span>
                            {projectSow.sowStatus === 'Issued' ? (<Button className="next_btn next_btn_lg "
                                                                          disabled={!_.isEmpty(projectSow.sowDescriptions) ? false : true}
                                                                          onClick={toggleApprovalModal}
                            >{t('program.manager.issue.sow.btn.save.send')}
                            </Button>) : (<span></span>)}
                        </span>) : (<span>
                              {userInfo.role.internalName === 'SALES' && <span></span>}


                            {!_.isEmpty(projectSow) && <span>
                                    {(userInfo.role.internalName === 'MT' && projectSow.projectAdminMt.userInfo.name === userInfo.name) ? (
                                        <span>
                                    {projectSow.sowStatus === 'Initiated' && <span>
                                        <Button className="next_accent2_btn next_btn_lg me-2"
                                                onClick={toggleMtSowRejectModal}
                                            //style={{marginRight:"-5px"}}
                                        >{t('integr8.btn.reject')}
                                        </Button>
                                        <Button className="next_btn_success_integr8 next_btn_lg  "
                                                onClick={toggleMtSowAcceptModal}
                                        >{t('program.manager.view.invoice.approve.btn')}
                                        </Button>
                                    </span>}
                                </span>) : (<span></span>)}
                                  </span>

                            }
                            </span>)}
                    </span>
                </div>
            </Col>
        </Row>

        <Row>
            <Col md={12}>
                <div className="pb-2"><b>{t('program.manager.issue.sow.title.statment.work')}</b></div>
                <Card className={'border next_radious mt-1'}>

                    <Row className="issue_SOW_heading">
                        <Col md={1} className="pt-3 pb-3">
                            <div>{t('pm.sow.table.th.sow.no')}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{t('pm.sow.table.th.project.code.no')}</div>
                        </Col>
                        <Col md={3} className="pt-3 pb-3">
                            <div>{t('pm.sow.table.th.project.text')}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{t('pm.sow.table.th.client.text')}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{t('pm.sow.table.th.partner.text')}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{t('pm.sow.table.th.msa.issued.on.text')}</div>
                        </Col>
                    </Row>

                    <Row className="issue_SOW_body">
                        <Col md={1} className="pt-3 pb-3">
                            <div>{sowNumber}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{projectCode}</div>
                        </Col>
                        <Col md={3} className="pt-3 pb-3">
                            <div>{!_.isEmpty(projectSow.project) ? (<span>{projectSow.project.projectName}</span>) : (
                                <span></span>)}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{!_.isEmpty(projectSow) ? (
                                <span>{_.head(projectSow.project.projectClientMappers).client.name}</span>) : (
                                <span></span>)}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div> {!_.isEmpty(projectSow) ? (
                                <span>{projectSow.projectAdminPartner.userInfo.name}</span>) : (
                                <span></span>)}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{!_.isEmpty(msaIssuedDate) ? (<span>
                                                <Moment
                                                    format="D MMM YYYY">{_.head(msaIssuedDate).msaIssuedOn}</Moment>
                                            </span>) : (<span>-</span>)}</div>
                        </Col>
                    </Row>

                </Card>

                <span>{t('program.manager.issue.sow.think.talent.wish.body')}</span>
                <span>{t('program.manager.issue.sow.payment.info.text')}</span>
                <div className={'mt-3 mb-3'}>
                    {!_.isEmpty(projectSow) &&
                        <AddDescriptionModal projectSow={projectSow} findByProjectSow={findByProjectSow}/>}
                </div>
            </Col>
        </Row>

        <Row className="mt-3">
            <Col md={12}>
                {!_.isEmpty(sowDocumentTrackList) ? (<div>
                    <div className="pb-2"><b>{t('program.manager.issue.sow.title.document.track')}</b></div>
                    <Card className={'border next_radious mt-1'}>
                        <Row className="issue_SOW_heading">
                            <Col md={4} className="pt-3 pb-3">
                                <div>{t('pm.sow.table.th.status')}</div>
                            </Col>
                            <Col md={4} className="pt-3 pb-3">
                                <div>{t('pm.sow.table.th.user')}</div>
                            </Col>
                            <Col md={4} className="pt-3 pb-3">
                                <div>{t('pm.sow.table.th.date')}</div>
                            </Col>
                        </Row>

                        {sowDocumentTrackList.map((sowDocumentTrack, swtIndex) => <Row className="issue_SOW_body"
                                                                                       key={swtIndex}>
                            <Col md={4} className="pt-3 pb-3">

                                {sowDocumentTrack.sowStatus === 'Initiated' &&
                                    <div>{t('program.manager.sow.status.comment.initiated.text')}</div>}
                                {sowDocumentTrack.sowStatus === 'Approved' &&
                                    <div>{t('program.manager.sow.status.comment.approved.text')}</div>}
                                {sowDocumentTrack.sowStatus === 'Rejected' &&
                                    <div>{t('program.manager.sow.status.comment.rejected.text')}</div>}
                                {sowDocumentTrack.sowStatus === 'Accepted' &&
                                    <div>{t('program.manager.sow.status.comment.accepted.text')}</div>}
                                {sowDocumentTrack.sowStatus === 'Declined' &&
                                    <div>{t('program.manager.sow.status.comment.declined.text')}</div>}
                            </Col>
                            <Col md={4} className="pt-3 pb-3">
                                <div>{!_.isEmpty(sowDocumentTrack.projectAdmin.userInfo) ? sowDocumentTrack.projectAdmin.userInfo.name : ''}</div>
                            </Col>
                            <Col md={4} className="pt-3 pb-3">
                                <div><Moment local titleFormat="D MMM YYYY" format="D MMM YYYY"
                                             withTitle>{sowDocumentTrack.timestamp}</Moment></div>
                            </Col>
                            {!_.isEmpty(sowDocumentTrackList) && sowDocumentTrackList.length - 1 !== swtIndex &&
                                <hr style={{marginBottom: '0'}}/>}
                        </Row>)}
                    </Card>
                </div>) : (<div></div>)

                }

            </Col>
        </Row>


        {/* ======================================  Issue SOW PM Send Approval modal ===================================== */}

        <Modal
            isOpen={newApprovalModal}
            toggle={toggleApprovalModal}
            // className={className}
        >
            <ModalHeader
                toggle={toggleApprovalModal}>{t('program.manager.issue.sow.btn.save.send')}</ModalHeader>
            <ModalBody>

                <Row>
                    <Col md={3}>
                        <label>{t('program.manager.issue.sow.modal.body.select.member')}</label>
                    </Col>
                    <Col md={9}>
                        <Select
                            options={mtMemberList}
                            onChange={onMtMemberSelect}
                            classNamePrefix="next_select"
                            placeholder={t('integr8.select.placeholder.text')}/>
                    </Col>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button className="next_cancel_btn me-3"
                        onClick={toggleApprovalModal}>
                    {t('integr8.common.cancel.text')}
                </Button>

                <ButtonWithLoader
                    loading={isUpLoaded}
                    disabled={btnDisabled}
                    text={t('program.manager.issue.sow.modal.footer.btn.send')}
                    onClick={updateSowStatusByPm}
                    className="next_btn next_btn_lg"
                />
            </ModalFooter>
        </Modal>


        {/* ======================================  project-details SOW modal ===================================== */}

        <Modal
            isOpen={projectDetailsSowModal}
            toggle={toggleProjectDetailsSowModal}
            // className={className}
        >
            <ModalHeader
                toggle={toggleProjectDetailsSowModal}>{t('integr8.sow.project.details.btn')}</ModalHeader>
            <ModalBody className={'px-3'}>

                <div className="integr8_next_small_card">
                    <Row style={{marginTop: '10px'}}>
                        <Col sm={12} md={6} lg={6}>
                            <div className="card next_radious shadow-none text-white"
                                 style={{backgroundColor: '#FAC410', height: '70%'}}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="align-self-center ps-2">
                                            <img style={{height: '30px'}}
                                                 src={img1}
                                                 alt="total-goal"/>
                                        </div>
                                        <div className="align-self-center ps-3">
                                            <div
                                                className="mb-0 integr8_small_card_text">{t('integr8.estimated.project.value')}</div>
                                            {!_.isEmpty(project.projections) ? (<h6
                                                className="goal_count mt-1 ">₹{_.sumBy(project.projections, 'projectionAmount').toLocaleString('en-IN')}
                                            </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                            <div className="card next_radious shadow-none text-white"
                                 style={{backgroundColor: '#26C6DA', height: '70%', marginLeft: '-20px'}}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                                     src={img2}
                                                                                     alt="ontrack-goal"/></div>
                                        <div className="align-self-center ps-3">
                                            <div
                                                className="mb-0 integr8_small_card_text">{t('integr8.projected.cost.text')}</div>
                                            {!_.isEmpty(project.projectServiceCosts) ? (<h6
                                                className="goal_count mt-1 ">₹{_.sumBy(project.projectServiceCosts, 'totalCost').toLocaleString('en-IN')}
                                            </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row style={{marginTop: '-27px'}}>
                        <Col sm={12} md={6} lg={6}>
                            <div className="card next_radious shadow-none text-white"
                                 style={{backgroundColor: '#8961FF', height: '70%'}}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                                     src={img3}
                                                                                     alt="delayed-goal"/></div>
                                        <div className="align-self-center ps-3">
                                            <div
                                                className="mb-0 integr8_small_card_text">{t('integr8.amount.invoiced.text')}</div>
                                            {!_.isEmpty(project.projectInvoices) ? (<h6
                                                className="goal_count mt-1 ">₹{_.sumBy(project.projectInvoices, 'invoiceAmount').toLocaleString('en-IN')}
                                            </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <div className="card next_radious shadow-none text-white"
                                 style={{backgroundColor: '#4AC18E', height: '70%', marginLeft: '-20px'}}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                                     src={img4}
                                                                                     alt="close-goal"/></div>
                                        <div className="align-self-center ps-3">
                                            <div
                                                className="mb-0 integr8_small_card_text">{t('integr8.paid.by.client.text')}</div>
                                            {!_.isEmpty(project.projectInvoices) ? (<h6
                                                className="goal_count mt-1">₹{_.sumBy(project.projectInvoices, (invoice) => invoice.paymentStatus === 'Y' ? invoice.invoiceAmount : 0).toLocaleString('en-IN')}</h6>) : (
                                                <h6 className="goal_count mt-1">₹0</h6>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{marginTop: '-20px'}}>
                    {userInfo.role.internalName === 'PM' ? (<div>
                        <a
                            href={'/integr8/program-manager/individual-project/' + project.projectId_ENCRYPTED + '#2'}
                            target="_blank">{t('pm.project.details.modal.click.here')}</a>&nbsp;
                        <span>{t('pm.project.details.modal.text')}</span>
                    </div>) : (<div>
                        <a
                            href={'/integr8/management/individual-project/' + project.projectId_ENCRYPTED + '#2'}
                            target="_blank">{t('pm.project.details.modal.click.here')}</a>&nbsp;
                        <span>{t('pm.project.details.modal.text')}</span>
                    </div>)}


                </div>
            </ModalBody>
            <ModalFooter className={'pt-0'}>
                <Button className="next_cancel_btn me-3"
                        onClick={toggleProjectDetailsSowModal}>
                    {t('integr8.close.btn.text')}
                </Button>

            </ModalFooter>
        </Modal>

        {/* ======================================   MT SOW Accept modal ===================================== */}
        <Modal isOpen={sowAcceptModal}
               toggle={toggleMtSowAcceptModal}

               fade={false}
        >
            <ModalHeader toggle={toggleMtSowAcceptModal}>{t('partner.ptnr.view.sow.header.accept.modal')}</ModalHeader>
            <ModalBody>
                <div className="text-center" style={{fontWeight: 'bold'}}>{t('mt.view.sow.accept.modal.body')}</div>
            </ModalBody>

            <ModalFooter>
                <Button className="next_cancel_btn me-3"
                        onClick={toggleMtSowAcceptModal}>{t('integr8.common.cancel.text')}</Button>

                <ButtonWithLoader className="next_btn_success_integr8"
                                  onClick={approvedSowByMt}
                                  loading={isUpLoaded}
                                  text={t('mt.view.sow.approve.send.msg')}
                >
                </ButtonWithLoader>

            </ModalFooter>

        </Modal>

        {/* ======================================   MT SOW Reject modal ===================================== */}

        <Modal
            isOpen={sowRejectModal}
            toggle={toggleMtSowRejectModal}
            fade={false}
        >
            <ModalHeader toggle={toggleMtSowRejectModal}>{t('mt.view.sow.reject.modal.header')}</ModalHeader>
            <ModalBody>
                <div className="text-center" style={{fontWeight: 'bold'}}>{t('mt.view.sow.reject.modal.body')}</div>
            </ModalBody>
            <ModalFooter>
                <Button className="next_cancel_btn me-3" onClick={toggleMtSowRejectModal}>
                    {t('integr8.common.cancel.text')}
                </Button>

                <ButtonWithLoader className="next_accent2_btn"
                                  onClick={rejectedSowByMt}
                                  loading={isUpLoaded}
                                  text={t('integr8.btn.reject')}
                >

                </ButtonWithLoader>

            </ModalFooter>
        </Modal>
    </div>);
}

export default PmMtSalesSowIssue;