import React from "react";

export const EditextProps ={
    editButtonContent:(<i className="fas fa-pencil-alt"></i>),
    cancelButtonContent:(<i className="fas fa-times"></i>),
    saveButtonContent:(<i className="fas fa-check"></i>),
    editButtonClassName:"EdiText-custom-edit-button",
    saveButtonClassName:"EdiText-custom-save-button",
    cancelButtonClassName:"EdiText-custom-cancel-button"
}
