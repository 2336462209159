import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { createTheme, ThemeProvider } from '@mui/material';
import { useState } from 'react';

const defaultOptions = {
    enableColumnFilterModes: false,
    enableColumnOrdering: false,
    enableGrouping: false,
    enableColumnPinning: true,
    enableRowActions: false,
    enableRowSelection: false,


    state: {
        showLoadingOverlay: true,
        showProgressBars: false,
        showSkeletons: true,
    },
    initialState: {
        showColumnFilters: false,
        pagination: {pageSize: 25, pageIndex: 0},
        density: 'comfortable',
        showLoadingOverlay: true,
        showProgressBars: false,
        showSkeletons: true,

    },
    enablePagination: true,
    enableBottomToolbar: true,
    enableRowNumbers: false,
    rowNumberDisplayMode: 'static',

    muiSkeletonProps: {
        animation: 'wave',
    },
    /* muiLinearProgressProps: {
         color: 'info',
     },
     muiCircularProgressProps: {
         color: 'info',
     },*/
};

/**
 * The MaterialTable function is a React component that renders a Material-UI table.
 *
 *
 * @param {columns Pass in the columns that will be used to build the table
 * @param data Pass in the data that will be displayed in the table
 * @param options} Pass in the options object to materialreacttable
 *
 * @return A react component
 *
 * @docauthor Trelent
 */
function NextMaterialTable({columns, data, options}) {
    const [rowSelection, setRowSelection] = useState({});
    const tableOptions = {...defaultOptions, ...options,};

    const table = useMaterialReactTable({
        columns,
        data,
        ...tableOptions,
        state: {...tableOptions.state, ...options.state, rowSelection: rowSelection},
        initialState: { ...tableOptions.initialState, ...options.initialState },
        onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
        enableColumnDragging: false,
        enableColumnOrdering: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnActions: false,
        enableColumnFilterModes: false,
        enableColumnVirtualization: false,


    });


    const theme = createTheme({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    head: { // Styles for header cells
                        backgroundColor: '#f4f9fe !important', // Set your desired color for header
                        fontSize: '12px !important',
                        color: 'rgb(94, 94, 94) !important',
                        padding: '16px !important',
                        fontFamily: 'Poppins !important',
                    },
                    body: { // Styles for body cells
                        fontFamily: 'Poppins !important',
                        fontSize: '13px !important',
                        padding: '16px !important',
                        background: 'none !important',
                    },
                },
            },
        },
    });

    return (<div className="material-table-v2">
        <ThemeProvider theme={theme}>
            <MaterialReactTable table={table}/>
        </ThemeProvider>
    </div>);
}

export default NextMaterialTable;