export const sunEditorExtraOptions = {
    linkProtocol: "https://",
    imageSizeOnlyPercentage: true,
    videoFileInput: false,
    videoSizeOnlyPercentage: true,
    audioFileInput: false,
    imageUploadSizeLimit: "2097152",
    attributesWhitelist: {
         "all": "*",
         '???': '*',
    },
    pasteTagsWhitelist: {
        "all": "*",
        '???': '*',
    },
     attributesBlacklist: {
        "img": "onerror",
    },
    strictMode: true,
};