import EdiText from 'react-editext'
import styled from "styled-components";

export const StyledEdiText = styled(EdiText)`

  div[editext="view-container"], div[editext="edit-container"] {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px !important;
    text-overflow: ellipsis !important;
  }

  input, textarea {

    border-radius: 20px;
  }
`;

export const EdiTextInstructions = styled(EdiText)`

  div[editext="view-container"], div[editext="edit-container"] {
    width: 100%;
    border-radius: 5px !important;
    text-overflow: ellipsis !important;
    //pre tag
    box-sizing: border-box;
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap !important;
    white-space: -o-pre-wrap;
    word-wrap: break-word;

  }

  input, textarea {
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
    height: fit-content;
  }

  textarea {
    height: max-content;
  }
`;

export const TemplateNameEdiText = styled(EdiText)`

  div[editext="view-container"], div[editext="edit-container"] {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px !important;
    text-overflow: ellipsis !important;
  }

  input, textarea {

    border-radius: 20px;
  }
`;

export const StyledEdiTextPwd = styled(EdiText)`

  div[editext="view-container"], div[editext="edit-container"] {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px !important;
    text-overflow: ellipsis !important;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  input, textarea {

    border-radius: 20px;
  }
`;

export const PackageEdiText = styled(EdiText)`

  div[editext="view-container"], div[editext="edit-container"] {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }

  input, textarea {

    border-radius: 20px;
  }
`;

export const BasicEdiText = styled(EdiText)`

  div[editext="view-container"], div[editext="edit-container"] {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }

`;


export const IdpEditTextInput = styled(EdiText)`

  div[editext="view-container"], div[editext="edit-container"] {
    width: 100%;
    border-radius: 5px !important;
    font-size: 13px;

    overflow: hidden;

  }

  input, textarea {

    border-radius: 20px;
  }
`;


export const IdpEditTextElipsisInput = styled(EdiText)`

  div[editext="view-container"], div[editext="edit-container"] {
    width: 100%;
    border-radius: 5px !important;
    display: flex;

    font-size: 13px;

  }

  div[editext="view-container"] :first-child {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
textarea{
padding:15px
}
  input, textarea {
    width: 85% !important;
    border-radius: 20px;
    
  }
`;