import React from "react";
import {Spinner} from "reactstrap";

export default class LoaderSpinner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false
        };
    }

    render() {

        return (
            <div className="text-center page_loader">

                {/*<Oval  color="#00BFFF"   secondaryColor="#FFFFFF" height={40} width={40} ariaLabel="loading"/>*/}
                <Spinner color="info"
                         style={{
                             height: '2rem',
                             width: '2rem'
                         }}>
                    Loading...
                </Spinner>

            </div>

        );


    }
}
