import React, {lazy} from 'react';
import Loadable from '../layouts/loader/Loadable';
import conf_prop from '../properties/properties';
import {Navigate} from 'react-router-dom';


import PmMtIndividualProjectView from '../pages/common-components/pm-mt-components/pm-mt-individual-project';
import PartnerProfile from '../pages/program-manager/pm-mt-finance-partner-profile';
import PmMtPartnerFinanceViewInvoice
    from '../pages/common-components/pm-mt-partner-finance-components/pm-mt-partner-finance-view-invoice';
import PmMtSinglePartnerProfileView from '../views/pm-module/individual-project/pm-mt-single-partner-profile-view';
import PmMtSalesSowIssue from '../pages/program-manager/pm-mt-sales-sow-issue';
import PmMtDocumentRepository from '../pages/program-manager/document-repository';
import GoToOldIntegr8 from '../pages/program-manager/go-to-old-integr8';
import PmMtSalesManageContacts from '../pages/common-components/pm-mt-sales-components/pm-mt-sales-manage-contacts';
import MtSalesFinanceDashboard
    from '../pages/common-components/mt-sales-dashboard-components/mt-sales-finance-dashboard';


const MtProjects = Loadable(lazy(() => import('../pages/mt-member/mt-projects')));
const MtMyapprovals = Loadable(lazy(() => import('../pages/mt-member/mt-approvals')));
const MtPartnerPayments = Loadable(lazy(() => import('../pages/mt-member/mt-partner-payments')));
const ManageContacts = Loadable(lazy(() => import('../pages/common-components/pm-mt-sales-components/pm-mt-sales-manage-contacts')));
const ClientListView = Loadable(lazy(() => import('../pages/program-manager/client-list')));
const UpdatesView = Loadable(lazy(() => import('../pages/common-components/user-updates')));
const PmMtFinanceMyProfile = Loadable(lazy(() => import('../pages/common-components/pm-mt-finance-components/pm-mt-finance-my-profile')));


const route_path = conf_prop.get('route_path');

const MtMemberRoutes = [

    {
        path: `${route_path}/management/projects`,
        name: 'All Projects',
        exact: true, element: <MtProjects/>,
    },

    {
        path: `${route_path}/management/approval`,
        name: 'My Approvals', exact: true,
        element: <MtMyapprovals/>,
    },

    {
        path: `${route_path}/management/payments`,
        name: 'Partner Payments',
        exact: true, element: <MtPartnerPayments/>,
    },

    {
        path: `${route_path}/management/client-list`,
        name: 'Client List',
        exact: true,
        element: <ClientListView/>,
    },

    {
        path: `${route_path}/management/my-profile`,
        name: 'My Profile',
        exact: true, element: <PmMtFinanceMyProfile/>,

    },

    {
        path: `${route_path}/management/individual-project/:projectId_ENCRYPTED`,
        name: '',
        exact: true,
        element: <PmMtIndividualProjectView/>,
    },

    {
        path: `${route_path}/management/partner-profile`,
        name: 'Partner Profiles',
        exact: true,
        element: <PartnerProfile/>,
    },

    {
        path: `${route_path}/management/partner-single-profile/:userId_ENCRYPTED`,
        name: 'view-profile',
        exact: true,
        element: <PmMtSinglePartnerProfileView/>,
    },

    {
        path: `${route_path}/management/document-repository`,
        name: 'Standard Documents',
        exact: true,
        element: <PmMtDocumentRepository/>,
    },

    {
        path: `${route_path}/management/view-sow/:psowId_ENCRYPTED`,
        name: 'view issue',
        exact: true,
        element: <PmMtSalesSowIssue />,
    },

    {
        path: `${route_path}/management/view-invoice/:sowiId_ENCRYPTED`,
        name: 'view-invoice',
        exact: true,
        element: <PmMtPartnerFinanceViewInvoice/>,
    },

    {
        path: `${route_path}/management/updates`,
        name: 'Updates',
        exact: true,
        element: <UpdatesView/>,
    },
    {
        path: `${route_path}/management/manage-contacts`,
        name: 'Manage Contacts',
        exact: true,
        element: <PmMtSalesManageContacts/>,
    },
    {
        path: `${route_path}/management/oldintegr8`,
        name: 'Go to Old Integr8',
        exact: true,
        element: <GoToOldIntegr8/>,
    },
    {
        path: `${route_path}/management/dashboard`,
        name: 'Dashboard',
        exact: true,
        element: <MtSalesFinanceDashboard/>,
    },

    {
        path: '*',
        element: <Navigate to="/auth/404"/>
    },

];
export default MtMemberRoutes;
