import React from 'react';
import {useTranslation} from 'react-i18next';


//convert class to function

function PartnerUtilizationTab({billingPipeLineData,}) {
    const {t} = useTranslation();





    return (<div className="">
            <iframe title="Partner_Utilization_Tab"
                    width="1140px"
                    height="541.25" // Set height as needed
                    frameBorder="0" // Optional: to remove the border
                    src="https://app.powerbi.com/reportEmbed?reportId=b16421af-54cd-42c5-8bb1-8dad2ff29aa0&autoAuth=true&ctid=ba6ede6e-0563-4e79-84c4-b563adbc09a5" frameborder="0" allowFullScreen="true"></iframe>
    </div>);
}

export default PartnerUtilizationTab;
