import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

var UserDemographicController = {


    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userdemographic/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (udId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userdemographic/id/${udId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByUserInfo: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userdemographic/userinfo/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    insertUserDemographic: function (UserDemographic) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userdemographic/insert`, {
            method: "post",
            body: JSON.stringify(UserDemographic),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            })
        }).then(ApiUtils.checkStatus);
    },
    insertUserDemographicLocation: function (UserDemographic) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userdemographic/location-insert`, {
            method: "post",
            body: JSON.stringify(UserDemographic),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            })
        }).then(ApiUtils.checkStatus);
    },
    insertUserDemographicIndustry: function (UserDemographic) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userdemographic/industry-insert`, {
            method: "post",
            body: JSON.stringify(UserDemographic),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            })
        }).then(ApiUtils.checkStatus);
    },
    insertUserDemographicLanguage: function (UserDemographic) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userdemographic/language-insert`, {
            method: "post",
            body: JSON.stringify(UserDemographic),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            })
        }).then(ApiUtils.checkStatus);
    },


    updateUserDemographic: function (UserDemographic) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userdemographic/update`, {
            method: "post",

            body: JSON.stringify(UserDemographic),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
    deleteUserDemographic: function (UserDemographic) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userdemographic/delete`, {
            method: "post",
            body: JSON.stringify(UserDemographic),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    }
};

export default UserDemographicController;
