import * as Yup from 'yup';

export const partnerBillingSchema = Yup.object().shape({
    billingName: Yup.string().trim().required('Billing name is required'),
    accountNumber: Yup.string().trim().required('Account number is required'),
    ifscCode: Yup.string().trim().required('IFSC code is required'),
    billingAddress: Yup.string().trim().required('Billing address is required'),
    // gstNo: Yup.string().trim().required('GST number is required'),
    panNo: Yup.string().trim().required('PAN number is required'),
    // postalAddress: Yup.string().trim().required('Postal address is required'),
});
