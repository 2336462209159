import React from "react";
import 'react-notifications-component/dist/theme.css';
import {string} from "prop-types";
import {Store} from 'react-notifications-component';


let options = {
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__bounceIn"],
    animationOut: ["animate__animated", "animate__bounceOut"],
    dismiss: {
        duration: 5000,
        onScreen: true
    }
};


export function toastify(type = string, header = string, message = string) {
    let iconClassName = 'fa fa-info-circle';
    if (type === 'success') {
        iconClassName = 'fa fa-check-circle';
    } else if (type === 'warn') {
        iconClassName = 'fa fa-exclamation-triangle';
    } else if (type === 'error') {
        iconClassName = 'fa fa-exclamation-circle';
    }


    const content = {
        // title: (<div><span style={{"padding-right": "3%"}}></span></div>),
        message: (<div className={'next_notification_body'}><i className={iconClassName}></i> <span
            style={{marginLeft: '5px'}} dangerouslySetInnerHTML={{__html: message}}/></div>),
    };
    switch (type) {
        case "error": {

            Store.addNotification({
                ...content,
                type: "danger",
                ...options
            });
            break;
        }
        case "warn": {

            Store.addNotification({
                ...content,
                type: "warning",
                ...options
            });
            break;
        }
        case "success": {

            Store.addNotification({
                ...content,
                type: "success",
                ...options
            });
            break;
        }
        case "info": {

            Store.addNotification({
                ...content,
                type: "info",
                ...options
            });
            break;
        }

        default: {

            Store.addNotification({
                ...content,
                type: "default",
                ...options
            });
            break;
        }
    }


}

