import React, {lazy} from "react";

import Loadable from "../layouts/loader/Loadable";
import conf_prop from "../properties/properties";
import {Navigate} from "react-router-dom";

const ClientList = Loadable(lazy(() => import("../pages/notification/client-list")));
const ErrorBoundary401 = Loadable(lazy(() => import("../ErrorBoundary401")));
const Maintenance = Loadable(lazy(() => import("../pages/maintenance/maintenance")));


const route_path = conf_prop.get("route_path");

const NotificationRoutes = [
    {
        path: `${route_path}/notification/client-list`,
        name: 'ClientList',
        exact: true,
        element: <ClientList/>,
    },
    {
        path: `${route_path}/notification/error-unauthorized`,
        name: 'LoginClientView',
        exact: true,
        element: <ErrorBoundary401/>
    },
    {
        path: `${route_path}/notification/partner-maintenance`,
        name: 'userNotificationView',
        exact: true,
        element: <Maintenance/>
    },

    {path: '*', element: <Navigate to="/auth/404"/>},
];
export default NotificationRoutes;
