import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

var UserInfoDocumentController = {


    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfodocument/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByUserInfo: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfodocument/userinfo/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (udId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfodocument/id/${udId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },
    insertMsaIssuedOnAndMsaExpiryWithOutDocument: function (UserInfoDocument) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfodocument/insert/msaIssued-msaExpiry-with-out-doc`, {
            method: "post",

            body: JSON.stringify(UserInfoDocument),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },


    updateUserInfoDocument: function (UserInfoDocument) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfodocument/update`, {
            method: "post",

            body: JSON.stringify(UserInfoDocument),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    uploadMSAWithDocument: function (UserInfoDocument) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfodocument/file/upload-with-doc`, {
            method: "post",

            body: UserInfoDocument,

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                // 'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    uploadNDAFile: function (UserInfoDocument) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfodocument/file/upload/nda`, {
            method: "post",

            body: UserInfoDocument,

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                // 'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

};

export default UserInfoDocumentController;
