import React, {useEffect} from "react";
import ApiUtils, {encodeJwtString} from "../../api/ApiUtils";
import conf_prop from "../../properties/properties";
import LoaderSpinner from "../../components/utils/loader-spinner";

function GoToOldIntegr8() {

    let userInfo = ApiUtils.getLocalStorage('userInfo');
    const [isLoaded, setIsLoaded] = React.useState(false);

    async function redirect() {
        // const siteDomain = _.isEmpty(this.state.buyingPortalClientAdmin.buyingPortalClient.siteDomain) ? conf_prop.get("nextUrl") : this.state.buyingPortalClientAdmin.buyingPortalClient.siteDomain;
        const siteDomain = conf_prop.get("oldIntegr8Url");
        // console.log('userInfo', userInfo)
        const id_token = await encodeJwtString({email: userInfo.email});
        // console.log('siteDomain', siteDomain)
        // console.log('id_token', id_token)
        /**
         * Redirect to client if Content is already cloned for that client
         */

        window.location = `https://${siteDomain}/oauth-service/sso/sso-authorized?id_token=${id_token}&redirecturi=https://${siteDomain}/login/integr8-oauth-login-authorized`;

        // window.location(`https://${siteDomain}/oauth-service/sso/sso-authorized?id_token=${id_token}&redirecturi=https://${siteDomain}/login/integr8-oauth-login-authorized`, '_blank');
    }


    useEffect(() => {
        setIsLoaded(true)

        async function fetchData() {
            setTimeout(() => {
                redirect();
                setIsLoaded(false)
            }, 1000);
        }

        fetchData();

    }, [])


    if (!isLoaded) {
        return (<div style={{marginTop: '15%'}}><LoaderSpinner/></div>);
    } else {
        (<div>
            <h1>Go to Old Integr8</h1>
        </div>)
    }
}

export default GoToOldIntegr8;