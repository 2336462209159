import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import DataTableView from '../../../components/data-table/data-table-view';
import Moment from 'react-moment';
import ApiUtils from '../../../api/ApiUtils';


function PmMtInvoiceTab({sowInvoices}) {

    let userInfo = ApiUtils.getLocalStorage("userInfo")
    const {t} = useTranslation();
    const columns = useMemo(() => [{
        name: "SOW No",
        sortable: true,
        filter: (rowData, value) => rowData.psowId.toString().toLowerCase().includes(value.toLowerCase()),
        selector: rowData => rowData.psowId,
    },

        {
            name: "Invoice No",
            sortable: true,
            filter: (rowData, value) => (`INV${rowData.sowiId}`).toString().toLowerCase().includes(value.toLowerCase()),
            //filter: (rowData, value) => rowData.sowiId.toString().toLowerCase().includes(value.toLowerCase()),
            selector: rowData => rowData.sowiId,
            cell: rowData => {
                return (<div>INV{rowData.sowiId}</div>)
            }
        }, {
            name: "Raised on",
            sortable: true,
            cell: (rowData) => <Moment format="DD/MM/YYYY">{rowData.genDate}</Moment>,
        }, {
            name: "Amount",
            sortable: true,
            filter: (rowData, value) => rowData.totalAmt.toString().toLowerCase().includes(value.toLowerCase()),
            cell: rowData => <span>{rowData.totalAmt.toLocaleString('en-IN')}</span>,
        }, {
            name: "Status",
            sortable: true,
            filter: (rowData, value) => rowData.invoiceStatus.toLowerCase().includes(value.toLowerCase()),
            selector: rowData => rowData.invoiceStatus,
            cell: (rowData) => <div>
                {rowData.invoiceStatus === "PIssued" &&
                    <span>{t("program.manager.invoice.status.comment.pissued.text")}</span>
                }
                {rowData.invoiceStatus === "PInitiated" &&
                    <span>{t("program.manager.invoice.status.comment.pinitiated.text")}</span>
                }
                {rowData.invoiceStatus === "PMApproved" &&
                    <span>{t("program.manager.invoice.status.comment.pmapproved.text")}</span>
                }
                {rowData.invoiceStatus === "PMRejected" &&
                    <span>{t("program.manager.invoice.status.comment.pmrejected.text")}</span>
                }
                {rowData.invoiceStatus === "MTApproved" &&
                    <span>{t("program.manager.invoice.status.comment.mtapproved.text")}</span>
                }
                {rowData.invoiceStatus === "MTRejected" &&
                    <span>{t("program.manager.invoice.status.comment.mtrejected.text")}</span>
                }
                {rowData.invoiceStatus === "FinanceApproved" &&
                    <div>
                        {rowData.invoicePaymentProgressStatus === "FullyPaid" ? (
                            <span>{t('integr8.finance.partner.payment.fully.paid.btn')}</span>) : (
                            <span>{t('integr8.finance.partner.payment.partially.paid.btn')}</span>)}
                    </div>
                }
            </div>
        }, {
            name: "",
            sortable: true,
            center: true,
            cell: (rowData) => <div className="text-center">
                {userInfo.role.internalName === 'PM' && (<div className="">
                    {rowData.invoiceStatus === "PInitiated" ? (
                        <a href={`/integr8/program-manager/view-invoice/${rowData.sowiId_ENCRYPTED}?extraParam=comingFromInsideProject`}
                           className="next_outline_accent1_btn next_btn_lg">{t("admin.client.view.table.view")}</a>) : (
                        <a href={"/integr8/program-manager/view-invoice/" + rowData.sowiId_ENCRYPTED}
                           className="btn-info btn-sm">{t("admin.client.view.table.view")}</a>)}
                </div>)}
                {userInfo.role.internalName === 'SALES' && (<div className="">
                    <a href={"/integr8/sales/view-invoice/" + rowData.sowiId_ENCRYPTED}
                       className="btn-info btn-sm">{t("admin.client.view.table.view")}</a>
                </div>)}
                {userInfo.role.internalName === 'MT' && (<div className="">
                    {(rowData.projectSow.projectAdminMt.userInfo.name === userInfo.name && rowData.invoiceStatus === "PMApproved") ? (
                        <a href={`/integr8/management/view-invoice/${rowData.sowiId_ENCRYPTED}?extraParam=comingFromInsideProject`}
                           className="next_outline_accent1_btn next_btn_lg">{t("admin.client.view.table.view")}</a>
                    ) : (
                        <a href={"/integr8/management/view-invoice/" + rowData.sowiId_ENCRYPTED}
                           className="btn-info btn-sm">{t("admin.client.view.table.view")}</a>
                    )}
                </div>)}
            </div>,
        }]);

    return (<div>
        <div className="feedback_table containt_e2e-datatable mt-3">
            <DataTableView
                title={t("pm.close.projects.title")}
                columns={columns}
                data={sowInvoices}
                options={{search: true, extendedSearch: false, pagination: true}}
            />
        </div>
    </div>);

}

export default PmMtInvoiceTab;