import React from 'react';
import {useTranslation} from 'react-i18next';


//convert class to function

function PartnerFeedbackTab({billingPipeLineData,}) {
    const {t} = useTranslation();


    return (<div className="">
            <iframe title="Partner_Feedback_Tab"
                    width="1140px"
                    height="541.25" // Set height as needed
                    frameBorder="0" // Optional: to remove the border
                    src="https://app.powerbi.com/reportEmbed?reportId=a0fc8eb8-f4af-4032-be6e-1397d9252ce6&autoAuth=true&ctid=ba6ede6e-0563-4e79-84c4-b563adbc09a5" frameborder="0" allowFullScreen="true"></iframe>
    </div>);
}

export default PartnerFeedbackTab;
