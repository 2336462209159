import React from "react";
import Select from "react-select";
import {generateFilterOptions} from "@devopsthink/next-data-table-component-nextjs";


export default function TableExtendedFilter(props) {
    const {setFilterOptions, columns, data, options} = props
    const filterOptions = generateFilterOptions(columns, data);

    return (
        <React.Fragment>

            <Select classNamePrefix="next_select" onChange={setFilterOptions} options={filterOptions}
                    isMulti={true}
                    closeMenuOnSelect={false}/>

        </React.Fragment>

    )
}

