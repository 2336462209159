import React, {useState} from "react";
import conf_prop from "../../properties/properties";
import userDefaultImg from "../../assets/images/users/user.png"
import _ from "lodash";
import UserInfoController from "../../api/controller/userinfo-controller";
import {useQuery} from '@tanstack/react-query';

const route_path = conf_prop.get("route_path");

// export const MessageUserPhoto = (props) => {
//     const [videoURL, setVideoURL] = useState(false);
//     const {message} = props;
//     if (!_.isEmpty(message.userClientMapper)) {
//
//         if (_.isEmpty(videoURL)) {
//             UserInfoController.findPhotoById(message.userClientMapper.userInfo.userId_ENCRYPTED).then(blob => {
//                 let videoURL = window.URL.createObjectURL(blob);
//                 setVideoURL(videoURL);
//
//             }).catch(error => {
//                 console.error(error);
//
//             });
//             return (
//                 <img className="round" alt={"user-photo"} src={userDefaultImg}/>
//             );
//         } else {
//             return (
//                 <img className="round" alt={"user-photo"}
//                      src={videoURL}/>
//             );
//         }
//
//
//     } else if (!_.isEmpty(message.clientAdmin)) {
//
//         if (_.isEmpty(videoURL)) {
//             UserInfoController.findPhotoById(message.clientAdmin.userInfo.userId_ENCRYPTED).then(blob => {
//                 let videoURL = window.URL.createObjectURL(blob);
//                 setVideoURL(videoURL);
//
//             }).catch(error => {
//                 console.error(error);
//
//             });
//             return (
//                 <img className="round" alt={"user-photo"} src={userDefaultImg}/>
//             );
//         } else {
//             return (
//                 <img className="round" alt={"user-photo"}
//                      src={videoURL}/>
//             );
//         }
//
//
//     } else {
//         return (<img className="round"
//                      src={userDefaultImg} alt={"user-photo"}/>)
//     }
// };


export const CommentUserPhoto = ({comment}) => {


    const {data: videoURL, isLoading, error} = useQuery({
        queryKey: ['commentUserPhoto', comment.userInfo.userId_ENCRYPTED],
        queryFn: () => UserInfoController.findPhotoById(comment.userInfo.userId_ENCRYPTED),
        select: (blob) => window.URL.createObjectURL(blob),
        enabled: !!comment.userInfo.userId_ENCRYPTED,
    });

    if (isLoading || error || !videoURL || _.isEmpty(comment.userInfo)) {
        return (
            <img className="round" alt={"user-photo"} src={userDefaultImg}/>
        );
    } else {
        return (
            <img className="round" alt={"user-photo"} src={videoURL}/>
        );
    }
};


// export const UserPhoto = ({userInfo}) => {
//
//     const fetchUserPhoto = async () => {
//
//         const blob = await UserInfoController.findPhotoById(userInfo.userId_ENCRYPTED);
//         return window.URL.createObjectURL(blob);
//
//     }
//
//
//     const {data: videoURL, isLoading, error} = useQuery({
//         queryKey: ['userPhoto', userInfo.userId_ENCRYPTED],
//         queryFn: fetchUserPhoto,
//         select: (blob) => window.URL.createObjectURL(blob),
//         enabled: !!userInfo.userId_ENCRYPTED,
//     });
//
//     if (isLoading || error || !videoURL || _.isEmpty(userInfo)) {
//         return (
//             <img
//                 className="rounded-circle integr8_profile_img"
//                 width="100"
//                 alt={"user-photo"}
//                 src="https://cdn.thinktalentws48.click/image/click_to_add_photo.png"
//             />
//         );
//     } else {
//         return (
//             <img
//                 className="rounded-circle integr8_profile_img"
//                 width="100"
//                 alt={"user-photo"}
//                 src={videoURL}
//             />
//         );
//     }
// };
export const UserPhoto = (props) => {
    const [videoURL, setVideoURL] = useState(false);
    const {userInfo} = props;
    if (!_.isEmpty(userInfo)) {
        if (_.isEmpty(videoURL)) {
            UserInfoController.findPhotoById(userInfo.userId_ENCRYPTED).then(blob => {
                let videoURL = window.URL.createObjectURL(blob);
                setVideoURL(videoURL);

            }).catch(error => {
                console.error(error);

            });
            return (
                <img className="rounded-circle integr8_profile_img" width="100" alt={"user-photo"}
                     src="https://cdn.thinktalentws48.click/image/click_to_add_logo.jpg"/>
            );
        } else {
            return (
                <img className="rounded-circle integr8_profile_img" width="100" alt={"user-photo"}
                     src={videoURL}/>
            );
        }

    } else {
        return (<img className="rounded-circle integr8_profile_img" width="100"
                     src={userDefaultImg} alt={"user-photo"}/>)
    }
};

// export const UpdatePhoto = ({userInfo}) => {
//
//     const fetchUserPhoto = async () => {
//
//         const blob = await UserInfoController.findPhotoById(userInfo.userId_ENCRYPTED);
//         return window.URL.createObjectURL(blob);
//
//     }
//
//
//     const {data: videoURL, isLoading, error} = useQuery({
//         queryKey: ['userPhoto', userInfo.userId_ENCRYPTED],
//         queryFn: fetchUserPhoto,
//         select: (blob) => window.URL.createObjectURL(blob),
//         enabled: !!userInfo.userId_ENCRYPTED,
//     });
//
//     if (isLoading || error || !videoURL || _.isEmpty(userInfo)) {
//         return (
//           <img
//             className="rounded-circle integr8_profile_img"
//             width="50"
//             alt={"user-photo"}
//             src={userDefaultImg}
//           />
//         );
//     } else {
//         return (
//           <img
//             className="rounded-circle integr8_profile_img"
//             width="50"
//             alt={"user-photo"}
//             src={videoURL}
//           />
//         );
//     }
// };

export const UpdatePhoto = (props) => {
    const [videoURL, setVideoURL] = useState(false);
    const {userInfo} = props;
    if (!_.isEmpty(userInfo)) {
        if (_.isEmpty(videoURL)) {
            UserInfoController.findPhotoById(userInfo.userId_ENCRYPTED).then(blob => {
                let videoURL = window.URL.createObjectURL(blob);
                setVideoURL(videoURL);

            }).catch(error => {
                console.error(error);

            });
            return (
                <img className="rounded-circle integr8_profile_img" width="50" alt={"user-photo"}
                     src="https://cdn.thinktalentws48.click/image/user_default.png"/>
            );
        } else {
            return (
                <img className="rounded-circle integr8_profile_img" width="50" alt={"user-photo"}
                     src={videoURL}/>
            );
        }

    } else {
        return (<img className="rounded-circle integr8_profile_img" width="50"
                     src="https://cdn.thinktalentws48.click/image/user_default.png" alt={"user-photo"}/>)
    }
};
export const PartnerPhoto = (props) => {
    const [videoURL, setVideoURL] = useState(false);
    const {userInfo} = props;
    if (!_.isEmpty(userInfo)) {
        if (_.isEmpty(videoURL)) {
            UserInfoController.findPhotoById(userInfo.userId_ENCRYPTED).then(blob => {
                let videoURL = window.URL.createObjectURL(blob);
                setVideoURL(videoURL);

            }).catch(error => {
                console.error(error);

            });
            return (
                <img className="rounded-circle integr8_profile_img" width="37" alt={"user-photo"}
                     src="https://cdn.thinktalentws48.click/image/click_to_add_logo.jpg"/>
            );
        } else {
            return (
                <img className="rounded-circle integr8_profile_img" width="37" alt={"user-photo"}
                     src={videoURL}/>
            );
        }

    } else {
        return (<img className="rounded-circle integr8_profile_img" width="37"
                     src={userDefaultImg} alt={"user-photo"}/>)
    }
};




