import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import update from 'immutability-helper';
import UserBillingController from '../../api/controller/userbilling-controller';

import ApiUtils from '../../api/ApiUtils';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ButtonWithLoader, toastify } from '../../components/utils';
import LoaderSpinner from '../../components/utils/loader-spinner';
import { AESEncryption } from '@devopsthink/react-security-util';
import { partnerBillingSchema } from '../../validation/add-partner-billing-modal-validation';


//convert class to function component
function BillingDetailsViewTab({userId_ENCRYPTED}) {

    const {t} = useTranslation()
    //const project = props.project
    const userInfo = ApiUtils.getLocalStorage("userInfo")
    const [isLoaded, set_isloaded] = useState(true);
    const [isUpLoaded, set_isUpLoaded] = useState(false);
    const [error, set_error] = useState(null);
    const [updateDetailsModal, set_updateDetailsModal] = useState(false);
    const [userBilling, set_userBilling] = useState({});
    const [newUserBilling, set_newUserBilling] = useState({
        billingAddress: '',
        postalAddress: '',
        billingName: '',
        accountNumber: '',
        ifscCode: '',
        gstNo: '',
        panNo: ''
    });
    const schma = useMemo(() => partnerBillingSchema, []);

    function disabileBillingBtn() {
        return !schma.isValidSync(newUserBilling);
    }

    function toggleUpdateDetails() {
        set_updateDetailsModal(!updateDetailsModal);
    }

   async function findUserBillingDetailsByPartner() {
      await  UserBillingController.findByUserInfo(userId_ENCRYPTED).then(
            result => {
                const userBilling = result[0];
                set_userBilling(userBilling)
                set_isloaded(true)
            })
    }


    function onEditUserBillingDetails(editUserBillingDetails) {

        set_newUserBilling(_.isEmpty(editUserBillingDetails) ? {} : editUserBillingDetails)
        toggleUpdateDetails();

    }

    function onBillingDetailsChange(e) {
        set_newUserBilling(update(newUserBilling, {[e.target.name]: {$set: e.target.value}}));
    }

    function insertUserBillingDetails() {
        const userId = parseInt(AESEncryption.decrypt(userId_ENCRYPTED));
        const userInfo = {'userId': userId};
        newUserBilling.userInfo = userInfo;
        set_isUpLoaded(true);
        UserBillingController.insertUserBilling(newUserBilling).then(
            result => {
                set_newUserBilling({})
                set_userBilling(result)

                toastify("success", t("partner.ptnr.blng.dtls.billing.updated"),t("partner.ptnr.blng.dtls.billing.updated"));

            }).catch((error) => {
            console.error(error);

        }).finally(() => {
            set_isUpLoaded(false);
            toggleUpdateDetails();
            findUserBillingDetailsByPartner();

        });
    }

    useEffect(() => {
        async function fetchData() {
            await findUserBillingDetailsByPartner();
        }

        fetchData();

    }, [])

    if (!isLoaded) {

        return (

            <LoaderSpinner/>


        )
    } else {

        return (

          <div className="mt-3 ms-3">
                {userInfo.role.internalName === 'PARTNER' &&
                    <Row className="mb-2">
                        <Col md={6}></Col>
                        <Col md={6}>
                          <div className="text-end me-3">
                                <Button className="next_btn"
                                        onClick={() => onEditUserBillingDetails(userBilling)}
                                > {t("partner.ptnr.blng.dtls.update.details")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                }

                <FormGroup>
                    <div className="mb-2 tw-text-sm"><strong>{t("partner.ptnr.blng.dtls.information.invoices")}</strong>
                    </div>

                    <small>{t("partner.ptnr.blng.dtls.information.generate.automate")}
                    </small>
                </FormGroup>
                <FormGroup>
                  <strong className={'tw-text-xs me-1'}>{t('partner.ptnr.blng.dtls.name.invoice')}</strong>
                  <span>  {_.isEmpty(userBilling) ? '' : userBilling.billingName}</span>
                </FormGroup>
                <FormGroup>
                  <strong className={'tw-text-xs me-1'}>{t('partner.ptnr.blng.dtls.account.invoice')}</strong>
                  <span>  {_.isEmpty(userBilling) ? '' : userBilling.accountNumber}</span>
                </FormGroup>
                <FormGroup>
                  <strong className={'tw-text-xs me-1'}>{t('partner.ptnr.blng.dtls.ifsc.invoice')}</strong>
                  <span>  {_.isEmpty(userBilling) ? '' : userBilling.ifscCode}</span>
                </FormGroup>
                <FormGroup>
                  <strong className={'tw-text-xs me-1'}>{t('partner.ptnr.blng.dtls.adress.invoice')}</strong>
                  <span> {_.isEmpty(userBilling) ? '' : userBilling.billingAddress}</span>
                </FormGroup>
                <FormGroup>
                  <strong className={'tw-text-xs me-1'}>{t('partner.ptnr.blng.dtls.gst.number')}</strong>
                  <span>  {_.isEmpty(userBilling) ? '' : userBilling.gstNo}</span>
                </FormGroup>
                <FormGroup>
                  <strong className={'tw-text-xs me-1'}>{t('partner.ptnr.blng.dtls.pan.number')}</strong>
                  <span>  {_.isEmpty(userBilling) ? '' : userBilling.panNo}</span>
                </FormGroup>
                <FormGroup>
                  <strong className={'tw-text-xs me-1'}>{t('partner.ptnr.blng.dtls.postal.address')}</strong>
                  <span>  {_.isEmpty(userBilling) ? '' : userBilling.postalAddress}</span>
                    <div><small>{t("partner.ptnr.blng.dtls.address.courier.msg")}</small></div>

                </FormGroup>
                {/* ====================================== Update Details Modal ===================================== */}
                <Modal
                    isOpen={updateDetailsModal}
                    toggle={toggleUpdateDetails}
                    size="lg"
                >
                    <ModalHeader
                        toggle={toggleUpdateDetails}>{t("partner.ptnr.blng.dtls.update.details")}</ModalHeader>
                    <ModalBody>
                        <Row className={'mb-2'}>
                            <Col md={4}
                                 className={'tw-text-xs mt-2 tw-font-semibold'}>{t("partner.ptnr.blng.dtls.name.invoicing")}:</Col>
                            <Col md={8}>
                                <Input className="next_input_br tw-text-xs" type="text"
                                       value={newUserBilling.billingName}
                                       onChange={onBillingDetailsChange}
                                       placeholder="Enter Name"
                                       name="billingName"
                                />
                            </Col>
                        </Row>
                        <Row className={'mb-2'}>
                            <Col md={4}
                                 className={'tw-text-xs mt-2 tw-font-semibold'}>{t("partner.ptnr.blng.dtls.account.invoicing")}:</Col>
                            <Col md={8}>
                                <Input className="next_input_br tw-text-xs"
                                       type="text"
                                       min={0}
                                       onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                       value={newUserBilling.accountNumber}
                                       onChange={onBillingDetailsChange}
                                       placeholder="Enter Account Number"
                                       name="accountNumber"
                                />
                            </Col>
                        </Row>
                        <Row className={'mb-2'}>
                            <Col md={4}
                                 className={'tw-text-xs mt-2 tw-font-semibold'}>{t("partner.ptnr.blng.dtls.ifsc.invoicing")}:</Col>
                            <Col md={8}>
                                <Input className="next_input_br tw-text-xs" type="text"
                                       value={newUserBilling.ifscCode}
                                       onChange={onBillingDetailsChange}
                                       placeholder="Enter IFSC Code"
                                       name="ifscCode"
                                />
                            </Col>
                        </Row>
                        <Row className={'mb-2'}>
                            <Col md={4} className={'tw-text-xs mt-2 tw-font-semibold'}>
                                <Label>{t("partner.ptnr.blng.dtls.address.invoicing")}:</Label></Col>
                            <Col md={8}>
                                <Input className="next_input_br tw-text-xs" type="text"
                                       value={newUserBilling.billingAddress}
                                       onChange={onBillingDetailsChange}
                                       placeholder="Enter Address"
                                       name="billingAddress"
                                />
                            </Col>
                        </Row>

                        <Row className={'mb-2'}>
                            <Col md={4} className={'tw-text-xs mt-2 tw-font-semibold'}>
                                <Label>{t("partner.ptnr.blng.dtls.update.gst.number")}:</Label>
                            </Col>
                            <Col md={8}>
                                <Input className="next_input_br tw-text-xs" type="text" value={newUserBilling.gstNo}
                                       onChange={onBillingDetailsChange}
                                       placeholder="Enter GST"
                                       name="gstNo"
                                />
                            </Col>
                        </Row>
                        <Row className={'mb-2'}>
                            <Col md={4}
                                 className={'tw-text-xs mt-2 tw-font-semibold'}><Label>{t("partner.ptnr.blng.dtls.update.pan.number")}:</Label></Col>
                            <Col md={8}>
                                <Input className="next_input_br tw-text-xs" type="text" value={newUserBilling.panNo}
                                       onChange={onBillingDetailsChange}
                                       placeholder="Enter PAN"
                                       name="panNo"
                                />
                            </Col>
                        </Row>
                        <Row className={'mb-2'}>
                            <Col md={4} className={'tw-text-xs mt-2 tw-font-semibold'}>
                                <Label>{t("partner.ptnr.blng.dtls.update.postal.adress")}:</Label>
                            </Col>
                            <Col md={8}>
                                <Input className="next_input_br tw-text-xs" type="text"
                                       value={newUserBilling.postalAddress}
                                       onChange={onBillingDetailsChange}
                                       placeholder="Enter Postal Address"
                                       name="postalAddress"
                                />
                            </Col>
                        </Row>
                        <Row className={'mt-2'}>
                            <Col><small>*We will use this address to courier items to you if required.</small></Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className={'next_cancel_btn'} onClick={toggleUpdateDetails}>
                            {t("integr8.common.cancel.text")}
                        </Button>

                        <ButtonWithLoader
                            className="next_btn  ms-2"
                            loading={isUpLoaded}
                            disabled={disabileBillingBtn()}
                            onClick={insertUserBillingDetails}
                            text={t("partner.ptnr.blng.dtls.postal.btn.update")}
                        >
                        </ButtonWithLoader>


                    </ModalFooter>
                </Modal>
            </div>

        )
    }
}

export default BillingDetailsViewTab;
