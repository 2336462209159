import React from "react";
import img from  './assets/images/opps.png'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null, errorInfo: null};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    render() {
        const {hasError, error, errorInfo} = this.state;
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <div className={'text-center mt-5'}>
                        <img src={img}/>
                    </div>
                    <div className={'container text-center mt-4 text-bold'}>
                        <h1 className={'mb-4'}>Internal server error</h1>
                        <h4>Please try after sometime or feel free to contact us, if the problem persists.</h4>

                      <div className={'d-none'}>  <h6 className={'mb-4'}>{JSON.stringify(error)}</h6>
                        <h6>{JSON.stringify(errorInfo)}</h6>

                      </div>
                    </div>


                </div>);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;