import React, {lazy} from 'react';
import Loadable from '../layouts/loader/Loadable';
import conf_prop from '../properties/properties';
import {Navigate} from 'react-router-dom';
import PmMtPartnerFinanceViewInvoice
    from '../pages/common-components/pm-mt-partner-finance-components/pm-mt-partner-finance-view-invoice';
import PmMtFinancePartnerProfile from '../pages/program-manager/pm-mt-finance-partner-profile';
import PmMtSinglePartnerProfileView from '../views/pm-module/individual-project/pm-mt-single-partner-profile-view';
import GoToOldIntegr8 from '../pages/program-manager/go-to-old-integr8';
import MtSalesFinanceDashboard
    from '../pages/common-components/mt-sales-dashboard-components/mt-sales-finance-dashboard';

const FinanceProjects = Loadable(lazy(() => import('../pages/finance/finance-projects')));
const PartnerFinanceIndividualProjectView = Loadable(lazy(() => import('../pages/common-components/partner-finance-components/partner-finance-individual-project')));
const FinancePartnerPayments = Loadable(lazy(() => import('../pages/finance/finance-partner-payment')));
const UpdatesView = Loadable(lazy(() => import('../pages/common-components/user-updates')));
const PmMtFinanceMyProfile = Loadable(lazy(() => import('../pages/common-components/pm-mt-finance-components/pm-mt-finance-my-profile')));

const route_path = conf_prop.get('route_path');

const FinanceRoutes = [


    {
        path: `${route_path}/finance/projects`, name: 'All Projects', exact: true, element: <FinanceProjects/>,
    },


    {
        path: `${route_path}/finance/payments`,
        name: 'Partner Payments',
        exact: true,
        element: <FinancePartnerPayments/>,
    },


    {
        path: `${route_path}/finance/my-profile`, name: 'My Profile', exact: true, element: <PmMtFinanceMyProfile/>,
    }, {
        path: `${route_path}/finance/individual-project/:projectId_ENCRYPTED`,
        name: '',
        exact: true,
        element: <PartnerFinanceIndividualProjectView/>,
    },

    {
        path: `${route_path}/finance/view-invoice/:sowiId_ENCRYPTED`,
        name: 'view-invoice',
        exact: true,
        element: <PmMtPartnerFinanceViewInvoice/>,
    }, {
        path: `${route_path}/finance/updates`, name: 'Updates', exact: true, element: <UpdatesView/>,
    }, {
        path: `${route_path}/finance/partner-profile`,
        name: 'Partner Profiles',
        exact: true,
        element: <PmMtFinancePartnerProfile/>,
    }, {
        path: `${route_path}/finance/partner-single-profile/:userId_ENCRYPTED`,
        name: 'view-profile',
        exact: true,
        element: <PmMtSinglePartnerProfileView/>,
    },
    {
        path: `${route_path}/finance/oldintegr8`,
        name: 'Go to Old Integr8',
        exact: true,
        element: <GoToOldIntegr8/>,
    },
    {
        path: `${route_path}/finance/dashboard`,
        name: 'Dashboard',
        exact: true,
        element: <MtSalesFinanceDashboard/>,
    },
    {path: '*', element: <Navigate to="/auth/404"/>},];
export default FinanceRoutes;
