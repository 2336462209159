import React, {lazy} from 'react';
import Loadable from '../layouts/loader/Loadable';
import conf_prop from '../properties/properties';
import {Navigate} from 'react-router-dom';
import GoToOldIntegr8 from "../pages/program-manager/go-to-old-integr8";

const ClientView = Loadable(lazy(() => import('../pages/admin/client-view')));
const AdminFinanceBillingDetailsView = Loadable(lazy(() => import('../pages/common-components/admin-finance-components/admin-finance-billing-details')));
const UserView = Loadable(lazy(() => import('../pages/admin/user-view')));
const PartnerDocs = Loadable(lazy(() => import('../pages/admin/partner-docs')));
const SettingsView = Loadable(lazy(() => import('../pages/admin/setting')));
const AdminUpdates = Loadable(lazy(() => import('../pages/admin/admin-updates')));
const DocumentView = Loadable(lazy(() => import('../pages/admin/document-view')));


const route_path = conf_prop.get('route_path');

const AdminRoutes = [


    {
        path: `${route_path}/admin/user`,
        name: 'Manage Users',
        exact: true,
        element: <UserView/>,
    },

    {
        path: `${route_path}/admin/client-directory`,
        name: 'ClientDirectory',
        exact: true,
        element: <ClientView/>,
    },

    {
        path: `${route_path}/admin/billing-details/:clientId_ENCRYPTED`,
        name: 'BillingDetails',
        exact: true,
        element: <AdminFinanceBillingDetailsView/>,
    },

    {
        path: `${route_path}/admin/partner-docs`,
        name: 'Partner Docs',
        exact: true,
        element: <PartnerDocs/>,
    },
    {
        path: `${route_path}/admin/document`,
        name: 'Document Repository',
        element: <DocumentView/>,
        exact: true,

    },
    {
        path: `${route_path}/admin/settings`,
        name: 'Settings',
        exact: true,
        element: <SettingsView/>,
    },
    {
        path: `${route_path}/admin/updates`,
        name: 'Updates',
        exact: true,
        element: <AdminUpdates/>,
    },
    {
        path: `${route_path}/admin/oldintegr8`,
        name: 'Go to Old Integr8',
        exact: true,
        element: <GoToOldIntegr8/>,
    },
    {path: '*', element: <Navigate to="/auth/404"/>},
];
export default AdminRoutes;
