import i18n from "i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import Fetch from "i18next-fetch-backend";
import Cache from "i18next-localstorage-cache";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import conf_prop from "./properties/properties"; // primary use cache

// .use(Backend)
// Enables the i18next backend
i18n
  // Enable automatic language detection
  .use(Backend)
  .use(Cache)
  // .use(LanguageDetector)
  // Enables the hook initialization module
  // .use(initReactI18next)
  .init({
    // we init with resourceskya karna hoga

    lng: localStorage.getItem('i18nextLng'),
    fallbackLng: conf_prop.get("lng"),
    preload: [conf_prop.get("lng")],
    debug: false,
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    backend: {
      backends: [
        LocalStorageBackend, // primary
        Fetch, // fallback
      ],
      backendOptions: [
        {
          /* below options */
          // prefix for stored languages
          prefix: 'i18next_res_integr8_',

          // expiration
          expirationTime: 60 * 60 * 1000,

          // Version applied to all languages, can be overriden using the option `versions`
          defaultVersion: '',

          // language versions
          versions: {},

          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.localStorage,
        },
        {
            loadPath: `${conf_prop.get("langServiceUrl")}/locales/download/integr8-{{lng}}.json`,
          crossDomain: true,
          allowMultiLoading: true,
          reloadInterval: false,
        },
      ],
    },
    cache: {
      // prefix for stored languages
      prefix: 'i18next_res_integr8_',

      // expiration
      expirationTime: 60 * 60 * 1000,

      // Version applied to all languages, can be overriden using the option `versions`
      defaultVersion: '',

      // language versions
      versions: {},

      // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
      store: window.localStorage,
    },

    keySeparator: false, // can use .
    nsSeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
        useSuspense: true,
    },
  });

export default i18n;
