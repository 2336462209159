import React, { useEffect, useState } from 'react';

import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';
import UserInfoController from '../../../api/controller/userinfo-controller';
import _ from 'lodash';
import img2 from '../../../assets/images/user.png';
import { useTranslation } from 'react-i18next';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SowInvoiceController from '../../../api/controller/sowinvoice-controller';
import DataTableView from '../../../components/data-table/data-table-view';
import { Rating } from 'react-simple-star-rating';
import PartnerDocumentsViewTab from '../../partner/partner-mydocument';
import UserDemographicController from '../../../api/controller/userdemographic-controller';
import PartnerPmProfileTab from '../../partner/partner-pm-profile';
import { UserPhoto } from '../../../pages/common-components/user-photo';
import BillingDetailsViewTab from '../../../pages/partner/partner-billing-details';
import ApiUtils from '../../../api/ApiUtils';


function PmMtSinglePartnerProfileView(props) {

    let selectUserInfo = ApiUtils.getLocalStorage("userInfo");
    const {t} = useTranslation();
    const location = useLocation();
    const {hash} = location;
    const navigate = useNavigate();
    const query_params = new URLSearchParams(location.search);

    const path_params = useParams();
    const {userId_ENCRYPTED} = path_params;
    const [activeTab, set_activetab] = useState(_.isEmpty(hash) ? 1 : parseInt(hash.replace("#", "")));
    const [userInfo, set_userInfo] = useState({});
    const [partnerInvoiceList, set_partnerInvoiceList] = useState([]);

    const [userDemographics, set_userDemographics] = useState([]);
    const [userDemographicLinkedin, set_userDemographicLinkedin] = useState({});
    const [userDemographicLocation, set_userDemographicLocation] = useState({});
    const [isLoaded, set_isLoaded] = useState(false);

    function renderTab(activeTab) {
        if (selectUserInfo.role.internalName !== "FINANCE") {
            switch (activeTab) {
                case 1:
                    return (<TabPane tabId={1}>
                        {!_.isEmpty(userId_ENCRYPTED) && <PartnerPmProfileTab userId_ENCRYPTED={userId_ENCRYPTED}/>}
                    </TabPane>);
                    break;
                case 2:
                    return (<TabPane tabId={2}>
                        {!_.isEmpty(userId_ENCRYPTED) && <PartnerDocumentsViewTab userId_ENCRYPTED={userId_ENCRYPTED}/>}
                    </TabPane>);
                    break;

                default:
                    break;
            }
        } else {
            switch (activeTab) {
                case 1:
                    return (<TabPane tabId={1}>
                        {!_.isEmpty(userId_ENCRYPTED) && <BillingDetailsViewTab userId_ENCRYPTED={userId_ENCRYPTED}/>}
                    </TabPane>);
                default:
                    break;
            }
        }

    }

    function handleChangeTab(activeTabInput) {
        set_activetab(activeTabInput);
    }

    async function findUserInfo() {
        await UserInfoController.findById(userId_ENCRYPTED).then(result => {
            // set_partnerList(result.filter(res => {
            //     return res.role.roleName === "Partner" || res.role.roleName === "Coach"
            // }));
            set_userInfo(result);
            set_isLoaded(true);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {

        });
    }

    async function findInvoiceListByPartner() {
        await SowInvoiceController.findByUserInfo(userId_ENCRYPTED)
            .then(results => {
                set_partnerInvoiceList(results);
            }).catch((error) => {
                console.error(error);
            }).finally(() => {

            });
    }

    const columns = [

        {
            name: t("pm.partner.view.table.project"),
            selector: row => row.projectSow.project.projectName,
            filter: (row, value) => row.projectSow.project.projectName.toString().toLowerCase().includes(value.toLowerCase()),
            sortable: true,
            maxWidth: "20%",
            filterable: true,
        }, {
            name: t('partner.project.column.client.text'),
            filter: (row, value) => _.head(row.projectSow.project.projectClientMappers).client.name.toString().toLowerCase().includes(value.toLowerCase()),
            selector: row => _.head(row.projectSow.project.projectClientMappers).client.name,
            sortable: true,
            maxWidth: "10%",
        }, {
            name: t("pm.partner.view.table.invoice"),
            selector: row => row.sowiId,
            filter: (row, value) => (`INV${row.sowiId}`).toString().toLowerCase().includes(value.toLowerCase()),
            sortable: true,
            maxWidth: "10%",
            filterable: true,
            cell: row => {
                return (<div>INV{row.sowiId}</div>)
            }
        }, {
            name: t("pm.partner.view.table.service.type.text"),
            selector: row => row.projectSow.sowDescriptions.map((desc) => desc.description).join('\n'),
            filter: (row, value) => row.projectSow.sowDescriptions
                .map((desc) => desc.serviceType.name)
                .join('\n')
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            sortable: true,
            maxWidth: "15%",
            filterable: true,
            cell: row => {
                return (<div>
                    {row.projectSow && Array.isArray(row.projectSow.sowDescriptions) ? row.projectSow.sowDescriptions.map((sowDescription, index) => (
                        <React.Fragment key={index}>
                            {sowDescription.serviceType.name}
                            <br/>
                        </React.Fragment>)) : '-'}
                </div>);
            }
        }, {
            name: t("pm.partner.view.table.fee.per.unit"),
            sortable: true,
            maxWidth: "10%",
            filterable: true,
            filter: (row, value) => row.projectSow.sowDescriptions
                .map((desc) => desc.fee)
                .join('\n')
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            cell: row => {
                return (<div>
                    {row.projectSow && Array.isArray(row.projectSow.sowDescriptions) ? row.projectSow.sowDescriptions.map((desc, index) => (
                        <React.Fragment key={index}>
                            {desc.fee}
                            <br/>
                        </React.Fragment>)) : '-'}
                </div>);
            }
        }, {
            name: t("pm.partner.view.table.total.feedbacks"), maxWidth: "15%", filterable: true, cell: row => {
                return (<div className="text-center">
                    <Rating size={25}
                            initialValue={row.pmPartnerInvoiceRating}
                            allowFraction={true}
                            readonly={true}
                    />
                </div>);
            }
        }, {
            name: t("pm.partner.view.table.total.pm.comments"),
            maxWidth: "20%",
            filter: (row, value) => row.pmPartnerInvoiceRatingComment.toString().toLowerCase().includes(value.toLowerCase()),
            cell: (rowData) => <div className="statement_line_clamp text_ellipsis mt-2"
                                    title={rowData.pmPartnerInvoiceRatingComment ? rowData.pmPartnerInvoiceRatingComment.replace(/<[^>]*>?/gm, '') : ''}
                                    dangerouslySetInnerHTML={{__html: rowData.pmPartnerInvoiceRatingComment}}
            />
        }];

    async function findByUserInfo() {

        await UserDemographicController.findByUserInfo(userId_ENCRYPTED)
            .then(result => {
                set_userDemographics(result);
                set_userDemographicLinkedin(_.find(result, {filterName: "My LinkedIn Profile"}));
                set_userDemographicLocation(_.find(result, {filterName: "Location"}));

            }).catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        async function fetchData() {
            await findUserInfo();
            await findInvoiceListByPartner();
            await findByUserInfo();
        }

        fetchData();

    }, [])


    if (!isLoaded) {
        return (<div style={{marginTop: '15%'}}><LoaderSpinner/></div>);
    } else {

        return (<div>
            <Row>

                <Col md={4}>
                    <Card className={'border next_radious'}>
                        <CardBody>
                            <div className="text-center">
                                {/*<img src={img2} className="rounded-circle" width="200"/>*/}
                                {!_.isEmpty(userInfo.mimeType) ? <UserPhoto userInfo={userInfo}/> :
                                    // <img src="https://cdn.thinktalentws48.click/image/logo_not_uploaded.png"
                                    //      className="rounded-circle integr8_profile_img" width="100"/>}
                                    <img src={img2} className="rounded-circle" width="100"/>}
                                <div className=" mt-3">{userInfo.name}</div>

                            </div>
                            <hr></hr>


                            <Row className="mt-2">
                                <Col md={12}>
                                    <div>
                                        <i className="fas fa-phone me-2" style={{color: 'gray'}}> </i>
                                        {_.isEmpty(userInfo.contactNumber) ? "Not updated" : userInfo.contactNumber}
                                    </div>
                                </Col>
                                <Col className="mt-2" md={12}>
                                    <div><i className="fas fa-envelope me-2" style={{color: 'gray'}}></i>
                                        {_.isEmpty(userInfo.email) ? "Not updated" : userInfo.email}
                                    </div>
                                </Col>
                                <Col className="mt-2" md={12}>
                                    <div><i className="fa-brands fa-linkedin me-2" style={{color: 'gray'}}></i>
                                        {_.isEmpty(userDemographicLinkedin) ? "Not updated" : userDemographicLinkedin.filterValue}
                                    </div>
                                </Col>
                                <Col className="mt-2" md={12}>
                                    <div><i className="fa-solid fa-location-dot me-2" style={{color: 'gray'}}></i>
                                        {_.isEmpty(userDemographicLocation) ? "Not updated" : userDemographicLocation.filterValue}
                                    </div>
                                </Col>
                            </Row>


                        </CardBody>

                    </Card>


                </Col>
                <Col md={8}>

                    {(selectUserInfo.role.internalName !== "FINANCE") ? (<Card className={'border next_radious'}>
                        <CardBody>
                            <div className="next_tabs next_client_tabs containt_e2e2">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            href={"#1"}
                                            className={classNames({active: activeTab === 1})}
                                            onClick={() => {
                                                handleChangeTab(1);
                                            }}
                                        >
                                            {t("integr8.single.partner.profile.text")}
                                        </NavLink>
                                    </NavItem>


                                    <NavItem>
                                        <NavLink
                                            href={"#2"}
                                            className={classNames({active: activeTab === 2})}
                                            onClick={() => {
                                                handleChangeTab(2);
                                            }}
                                        >
                                            {t("integr8.single.partner.document.text")}
                                        </NavLink>
                                    </NavItem>


                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    {renderTab(activeTab)}
                                </TabContent>
                            </div>
                        </CardBody>
                    </Card>) : (<Card className={'border next_radious'}>
                        <CardBody>
                            <div className="next_tabs next_client_tabs containt_e2e2">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            href={"#1"}
                                            className={classNames({active: activeTab === 1})}
                                            onClick={() => {
                                                handleChangeTab(1);
                                            }}
                                        >
                                            {t("integr8.single.partner.billing.details.text")}
                                        </NavLink>
                                    </NavItem>


                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    {renderTab(activeTab)}
                                </TabContent>
                            </div>
                        </CardBody>
                    </Card>)}


                </Col>
            </Row>
            {selectUserInfo.role.internalName !== "FINANCE" && <div className="feedback_table containt_e2e mt-5">
                <h6 className="ps-3"><b>{t("pm.partner.profile.table.header")}</b></h6>
                <DataTableView
                    title={t("pm.partner.profile.table.header")}
                    data={partnerInvoiceList}
                    columns={columns}
                    options={{
                        search: true, extendedSearch: false, pagination: true,
                    }}
                />
            </div>}


        </div>)
    }
}

export default PmMtSinglePartnerProfileView;
