import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

var ToolController = {


    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/tool/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (toolId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/tool/id/${toolId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    insertTool: function (Tool) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/tool/insert`, {
            method: "post",

            body: JSON.stringify(Tool),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateTool: function (Tool) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/tool/update`, {
            method: "post",

            body: JSON.stringify(Tool),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

};

export default ToolController;
