import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

var BillingInstructionController = {


    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/billinginstruction/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    insertBillingInstruction: function (billingInstruction) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/billinginstruction/insert`, {
            method: "post",

            body: JSON.stringify(billingInstruction),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    insertBillingInstructionWithFile: function (BillingInstruction) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/billinginstruction/file/insert`, {
            method: "post",

            body: BillingInstruction,

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                // 'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateBillingInstruction: function (BillingInstruction) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/billinginstruction/update`, {
            method: "post",

            body: JSON.stringify(BillingInstruction),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    findByUserInfo: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/billinginstruction/userinfo/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (biId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/billinginstruction/id/${biId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByProject: function (projectId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/billinginstruction/project/${projectId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },
    findBillingInstructionsByProject: function (projectId, page = 0, pagesize = 5) {
        const access_token = ApiUtils.getCookie("accessToken");
        const query_params = ApiUtils.queryString({page: page, pagesize: pagesize});

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/billinginstruction/billing-instructions/${projectId}?${query_params}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

};

export default BillingInstructionController;
