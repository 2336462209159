import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {store} from './store/Store';
import App from './App';

import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import {ReactNotifications} from "react-notifications-component";
import ErrorBoundary from "./ErrorBoundary";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const queryClient = new QueryClient();

root.render(
    <Provider store={store}>
        < BrowserRouter>
            <I18nextProvider i18n={i18n}>

                <ErrorBoundary>
                    <ReactNotifications/>
                    <QueryClientProvider client={queryClient}>
                        <App/>
                    </QueryClientProvider>
                </ErrorBoundary>
            </I18nextProvider>
        </BrowserRouter>
    </Provider>,

    // document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
