import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import conf_prop from '../../properties/properties';
import Moment from 'react-moment';
import _ from 'lodash';
import UserInfoDocumentController from '../../api/controller/userinfodocument-controller';
import LoaderSpinner from '../../components/utils/loader-spinner';
import DataTableView from '../../components/data-table/data-table-view';


//convert class to function component
function PartnerDocumentsViewTab({userId_ENCRYPTED}) {

    const {t} = useTranslation();
    const [userDocuments, set_userDocuments] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);


    async function findUserDocument() {
        await UserInfoDocumentController.findByUserInfo(userId_ENCRYPTED)
            .then(userDocuments => {
                setIsLoaded(true);
                set_userDocuments(userDocuments);
            }).catch(error => {
                setIsLoaded(true);
            })
    }


    useEffect(() => {
        async function fetchData() {
            await findUserDocument();
        }

        fetchData();
    }, [])


    const columns = [{
        name: 'Document',
        filter: (rowData, value) => rowData.docType.docTypeName.toString().toLowerCase().includes(value.toLowerCase()),
        sortable: true,
        selector: rowData => rowData.docType.docTypeName,
    }, {
        name: 'Issued on', center: true,
        //filter: (rowData, value) => rowData.msaIssuedOn.toLowerCase().includes(value.toLowerCase()),
        cell: (rowData) => (
            <div className="next_cell_font_size">
                {rowData.msaIssuedOn ? (
                    <Moment format="D MMM YYYY" withTitle>
                        {rowData.msaIssuedOn}
                    </Moment>
                ) : (
                    'N/A'
                )}
            </div>
        )
    }, {
        name: 'Download', center: true,
        cell: (rowData) => (<a
            className=""
            style={{borderRadius: '4px', fontSize: '12px'}}
            href={`${conf_prop.get("integr8ServiceUrl")}/rest/userinfodocument/user-document-download/${rowData.uidId_ENCRYPTED}`}
        >
            <i className="ti-download me-1" style={{color: '#37A4FE'}}/>{rowData.docOriginalName}
        </a>),
    },];

    if (!isLoaded) {

        return (

            <LoaderSpinner/>

        )
    }

    return (<div>
        <div className="mt-3 ">
            <DataTableView
                title=''
                columns={columns}
                data={_.orderBy(userDocuments, ['genDate']['desc'])}
                options={{search: true, extendedSearch: false, pagination: true}}
            />
        </div>


    </div>)

}

export default PartnerDocumentsViewTab;