import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties';

var ContactController = {


    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/contact/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (contactId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/contact/id/${contactId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    insertContact: function (contact) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/contact/insert`, {
            method: "post",

            body: JSON.stringify(contact),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
    insertClientContact: function (contact) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/contact/client-insert`, {
            method: "post",

            body: JSON.stringify(contact),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    insertProjectContact: function(contact) {
        const access_token = ApiUtils.getCookie('accessToken');

        return fetch(`${conf_prop.get('integr8ServiceUrl')}/rest/contact/project-contact-insert`, {
            method: 'post',

            body: JSON.stringify(contact),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json',

            }),
        }).then(ApiUtils.checkStatus);
    },


    updateContact: function (Contact) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/contact/update`, {
            method: "post",

            body: JSON.stringify(Contact),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateMapContact: function(Contact) {
        const access_token = ApiUtils.getCookie('accessToken');

        return fetch(`${conf_prop.get('integr8ServiceUrl')}/rest/contact/project-map-update`, {
            method: 'post',

            body: JSON.stringify(Contact),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json',

            }),
        }).then(ApiUtils.checkStatus);
    },

};

export default ContactController;
