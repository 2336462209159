import * as Yup from 'yup';
import * as yup from 'yup';

export const clientContactInsertSchema = Yup.object().shape({
  name: yup.string().trim().required(),
  designation: yup.string().trim().required(),
  email: yup.string().email().trim().required(),
    clientContactMappers: yup.array().min(1).of(
        yup.object().shape({
            client: yup.object().shape({
                clientId: yup.number().required(),
            }).required(),
        })
    ).required(),
});
