import React from 'react';
import {useTranslation} from 'react-i18next';


//convert class to function

function PartnerCalenderTab({billingPipeLineData,}) {
    const {t} = useTranslation();
    //const dispatch = useDispatch();




    return (<div className="">
            <iframe title="Partner_Calender_Tab"
                    width="1140px"
                    height="541.25" // Set height as needed
                    frameBorder="0" // Optional: to remove the border
                    src="https://app.powerbi.com/reportEmbed?reportId=a6473590-7003-439c-951d-bd5c8784d048&autoAuth=true&ctid=ba6ede6e-0563-4e79-84c4-b563adbc09a5" frameborder="0" allowFullScreen="true"></iframe>
    </div>);
}

export default PartnerCalenderTab;
