import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import _ from 'lodash';
import conf_prop from '../../../properties/properties';
import { toastify } from '../../../components/utils';
import ImageCroppier from '../../../components/utils/image-utility/croppie';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import ProjectController from '../../../api/controller/project-controller';
import { ProjectPhoto } from './pm-mt-project-photo';


const route_path = conf_prop.get("route_path");

function ProjectImageModal(props) {
    const {t} = useTranslation();
    const [isUpLoaded, set_isUpLoaded] = useState(false);
    const [isBtnLoading, set_isBtnLoading] = useState(false);
    const [project, set_project] = useState(props.project);
    const [userInfo, set_userInfo] = useState(props.userInfo);
    const [profilePictureModal, set_profilePictureModal] = useState(false);


    function toggleProfilePictureModal() {
        set_profilePictureModal(!profilePictureModal);

    }

    function uploadProfilePhoto(file) {

        set_isBtnLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("projectId", project.projectId_ENCRYPTED);
        let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
        if (!_.isEmpty(file) && (file.size / 1024 > 500)) {
            toastify("error", t("integr8.file.toastify.error.file.size.msg"), t("integr8.file.toastify.error.file.size.msg"));
        } else if (!(allowedExtension.indexOf(file.type) > -1)) {
            toastify("error", t("integr8.file.toastify.error.invalid.file.type.msg"), t("integr8.file.toastify.error.invalid.file.type.msg"));
        }
        ProjectController.uploadProjectImage(formData).then(result => {


            toastify("success", t("integr8.file.toastify.success.msg"), t("integr8.file.toastify.success.msg"));
            window.location.reload();
        }).catch(error => {
            console.error(error);
            toastify("error", t("integr8.file.toastify.error.msg"), t("integr8.file.toastify.error.msg"));
        }).finally(() => {
            set_isBtnLoading(false);
        })

    }

    return (
        <div>
            <div className="text-center">
                {(userInfo.role.internalName === "PM") ? (
                    <div>
                        {!_.isEmpty(project.mimeType) ?
                            <div>
                            <span onClick={toggleProfilePictureModal} style={{cursor: 'pointer'}}>
                                <ProjectPhoto
                                project={project}/>
                            </span>
                            </div>
                            : <img src="https://cdn.thinktalentws48.click/image/click_to_add_logo.jpg"
                                   className="rounded-circle integr8_profile_img" width="80"
                                   onClick={toggleProfilePictureModal} style={{cursor: 'pointer'}}/>
                        }
                        {/*<span className={"profile_img_upload"}>   <i className="fa-solid fa-camera tw-text-sm"
                                                                     style={{cursor: "pointer"}}
                                                                     onClick={toggleProfilePictureModal}></i></span>*/}
                    </div>

                ) : (
                    <div>
                        {!_.isEmpty(project.mimeType) ?
                            <ProjectPhoto project={project}/>
                            :
                            <img src="https://cdn.thinktalentws48.click/image/logo_not_uploaded.png"
                                 className="rounded-circle integr8_profile_img" width="80" />
                            /*<div className={'d-flex justify-content-center'}>
                                <div className="rounded-circle tw-bg-gray-500 text-white"
                                     style={{width: '100px', height: '100px',}}
                                >
                                    <div style={{
                                        marginTop: '29%',
                                        fontSize: "12px"
                                    }}>{t("integr8.logo.not.uploaded.text")}</div>
                                </div>
                            </div>*/
                        }
                    </div>
                )}

            </div>
            {/* ======================================    profile-picture modal ===================================== */}

            <Modal
                isOpen={profilePictureModal}
                toggle={toggleProfilePictureModal}
            >
                <ModalHeader
                    toggle={toggleProfilePictureModal}>{t("common.components.prfle.dtls.modal.profile.image")} </ModalHeader>
                <ModalBody>
                    {isUpLoaded === false &&
                        <div>
                            <div id="croppie"/>
                            <ImageCroppier uploadProfilePhoto={uploadProfilePhoto}/>
                        </div>
                    }
                    <small>{t(`integr8.profile.dtls.modal.image.upload.instruction`)}</small>
                    {isUpLoaded === true &&
                        <LoaderSpinner/>
                    }
                </ModalBody>

            </Modal>
        </div>
    )

}

export default ProjectImageModal;