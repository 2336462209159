import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

const ProjectAdminController = {




    findById: function (pcmId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectadmin/id/${pcmId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByProject: function (projectId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectadmin/project/${projectId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByUserInfo: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectadmin/userinfo/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByProjectAndUserInfo: function (projectId, userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectadmin/project/userinfo/${projectId}/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    }






};

export default ProjectAdminController;
