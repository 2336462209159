import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

var IndustryController = {


    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/industry/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (indId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/industry/id/${indId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    insertIndustry: function (Industry) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/industry/insert`, {
            method: "post",

            body: JSON.stringify(Industry),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateIndustry: function (Industry) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/industry/update`, {
            method: "post",

            body: JSON.stringify(Industry),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

};

export default IndustryController;
