import SecureLS from "secure-ls";
import {FetchUtil, JwtUtil} from "@devopsthink/react-security-util";

const secret = "ThinkLocalStorage@13972684";
const secureStorage = new SecureLS({encodingType: 'aes', encryptionSecret: secret});


export const encodeJwtString = JwtUtil.encodeJwtString;

export const decodeJwtString = JwtUtil.decodeJwtString;

const ApiUtils = {
    checkStatus: FetchUtil.checkStatus,
    checkResponse: FetchUtil.checkResponse,
    queryString: function (params) {
        return Object.entries(params).map(([key, value]) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }).join('&');
    },

    getCookie: function (cookieName) {

        try {
            return secureStorage.get(cookieName);
        } catch (err) {
            return null;
        }


    },
    setCookie: function (cookieName, cookieVal) {

        secureStorage.set(cookieName, cookieVal);
    },
    getLocalStorage: function (cookieName) {
        try {
            return secureStorage.get(cookieName);
        } catch (err) {
            return null;
        }

    },
    setLocalStorage: function (cookieName, cookieVal) {
        // const cookieVar = jwt.encode(cookieVal, secret);
        secureStorage.set(cookieName, cookieVal);
    }
};

export default ApiUtils;
