import React from "react";
import {Col, Input, InputGroup, InputGroupText, Row,} from "reactstrap";
import {useTranslation} from "react-i18next";

const FilterComponent = (props) => {
    const {filterText, onFilter, onClear} = props;
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <Row>
                <Col className={'pe-0'}>
                    <div className={'data_table_searchbar'}>
                        <InputGroup>

                            <InputGroupText><i className="fa fa-magnifying-glass"></i></InputGroupText>

                            <Input placeholder= {t('survey.filter.component.dta.table.search')}
                                   aria-label="Search Input"
                                   value={filterText} id="search"
                                   type="text"
                                   onChange={onFilter}/>


                            <InputGroupText onClick={onClear}>X</InputGroupText>

                        </InputGroup>
                    </div>


                </Col>

            </Row>

        </React.Fragment>
    )
};

export default FilterComponent;

