import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row,} from 'reactstrap';
import Select from 'react-select';
import {useTranslation} from "react-i18next";
import {ButtonWithLoader, toastify} from '../../../components/utils';
import ServiceTypeController from "../../../api/controller/servicetype-controller";
import update from "immutability-helper";
import SowDescriptionController from "../../../api/controller/sowdescription-controller";
import _ from "lodash";
import {addDescriptionModalBtnSchema} from "../../../validation/add-description-modal-validation";
import {updateDescriptionModalBtnSchema} from "../../../validation/add-description-modal-validation";


//convert class to function

function AddDescriptionModal({projectSow, findByProjectSow}) {

    const [isLoaded, set_isLoaded] = useState(false);
    const [isUpLoaded, set_isUpLoaded] = useState(false);
    const {t} = useTranslation();
    const [isInsertOpen, set_isInsertOpen] = useState(false);
    const [descriptionEditModal, set_descriptionEditModal] = useState(false);
    const [serviceTypeList, set_serviceTypeList] = useState([]);
    const [sowDescription, set_sowDescription] = useState({
        description: '', unit: 0, fee: 0, serviceType: {stId: ''}, projectSow: {}
    });
    const [updateSowDescription, set_updateSowDescription] = useState({
        sowdId: '', description: '', unit: '', fee: '', serviceType: {stId: ''}, projectSow: {}
    });
    const schema1 = useMemo(() => addDescriptionModalBtnSchema, []);
    const schema2 = useMemo(() => updateDescriptionModalBtnSchema, []);


    function disableAddDescriptionModalBtn() {
        return !schema1.isValidSync(sowDescription);
    }

    function disableUpdateDescriptionModalBtn() {
        return !schema2.isValidSync(updateSowDescription);
    }

    function toggleInsert() {
        set_isInsertOpen((isInsertOpenPrev) => {
            return !isInsertOpenPrev;
        });

        set_sowDescription({
            description: '', unit: 0, fee: 0, serviceType: {stId: ''}, projectSow: {}
        });
    }

    function editSowDescription() {
        set_descriptionEditModal((descriptionEditModalPrev) => {
            return !descriptionEditModalPrev;
        });
    }

    function editSowDescriptionModal(sowDescription) {
        set_updateSowDescription(sowDescription);
        editSowDescription()
    }

    function onDescriptionChange(e) {
        set_sowDescription(update(sowDescription, {description: {$set: e.target.value}}))
    }

    function onUnitChange(e) {
        set_sowDescription(update(sowDescription, {unit: {$set: e.target.value}}))
    }

    function onFeeChange(e) {
        set_sowDescription(update(sowDescription, {fee: {$set: e.target.value}}))
    }


    function onSowDescriptionChange(event) {
        const {name, value} = event.target;
        set_updateSowDescription({...updateSowDescription, [name]: value})
    }

    // function onProjectServiceChange(e) {
    //     set_projectServiceCost(update(projectServiceCost, {comments: {$set: e.target.value}}));
    // }
    // function onProjectServiceCostDetailsChange(event) {
    //     const {name, value} = event.target;
    //     set_updateProjectServiceCost({...updateProjectServiceCost, [name]: value});
    // }

    function onServiceTypeChange(selectedServiceType) {
        set_sowDescription(update(sowDescription, {'serviceType': {'stId': {$set: selectedServiceType.stId}}}))
    }

    function onUpdateServiceTypeChange(selectedServiceType) {
        set_updateSowDescription(update(updateSowDescription, {'serviceType': {'stId': {$set: selectedServiceType.stId}}}))
    }

    function insertSowDescription() {
        set_isUpLoaded(true);
        sowDescription.projectSow = projectSow;

        SowDescriptionController.insertSowDescription(sowDescription).then(result => {

        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            toggleInsert();
            set_isUpLoaded(false);
            findByProjectSow();
        });
    }

    async function findServiceTypeList() {
        await ServiceTypeController.findAll().then(result => {
            const serviceTypeList = result.map(serviceType => {
                serviceType.label = serviceType.name;
                serviceType.value = serviceType.stId;
                return serviceType;
            });
            set_serviceTypeList(serviceTypeList);
        }).catch(error => {
            console.error(error);
        })
    }

    function updateSowDescriptionData() {
        set_isUpLoaded(true);
        const curProjectSow = {'psowId': projectSow.psowId};
        updateSowDescription.projectSow = curProjectSow;
        SowDescriptionController.updateSowDescription(updateSowDescription).then(result => {
            toastify("success", t("integr8.common.update.success"), t("integr8.common.update.success"));
        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            editSowDescription();
            set_isUpLoaded(false);
            findByProjectSow();
        });
    }

    useEffect(() => {
        async function fetchData() {
            await findServiceTypeList();
        }

        fetchData();

    }, []);


    return (<span>

        {!_.isEmpty(projectSow) && projectSow.sowStatus === "Issued" ? (
            <Button className="next_outline_accent1_btn next_btn_lg"
                    onClick={toggleInsert}>{t("integr8.sow.add.description.btn")}
            </Button>) : (<span></span>)}
        {!_.isEmpty(projectSow.sowDescriptions) && projectSow.sowDescriptions.length > 0 && (<div>
            <Card className={'border next_radious mt-3'}>
                <Row className="issue_SOW_heading">
                    <Col md={4} className="pt-3 pb-3">
                        <div>{t("integr8.sow.thead.descriptions.text")}</div>
                    </Col>
                    <Col md={2} className="pt-3 pb-3">
                        <div>{t("integr8.sow.thead.service.text")}</div>
                    </Col>
                    <Col md={2} className="pt-3 pb-3">
                        <div>{t("integr8.sow.thead.unit.text")}</div>
                    </Col>
                    <Col md={2} className="pt-3 pb-3">
                        <div>{t("integr8.sow.thead.fees.text")}</div>
                    </Col>
                    <Col md={2} className="pt-3 pb-3"></Col>
                </Row>

                {!_.isEmpty(projectSow) && projectSow.sowDescriptions.map((sowDescription, index) => (
                    <Row className="issue_SOW_body" key={index}>
                        <Col md={4} className="pt-3 pb-3">
                            <div>{sowDescription.description}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{sowDescription.serviceType.name}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{sowDescription.unit}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div>{sowDescription.fee.toLocaleString('en-IN')}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div> {projectSow.sowStatus === "Issued" ? (<Button className="next_outline_btn next_btn_lg"
                                                                                onClick={() => editSowDescriptionModal(sowDescription)}>Edit
                            </Button>) : (<span></span>)}</div>
                        </Col>
                        {!_.isEmpty(projectSow) && projectSow.sowDescriptions.length - 1 !== index && <hr style={{marginBottom:'0'}}/>}
                    </Row>))}

            </Card>
            <small>{t("program.manager.issue.sow.note.computerized.document")}
            </small>
        </div>)}


        {/* ===========================================   SOW Description Add modal ======================================== */}


        <Modal isOpen={isInsertOpen}
               toggle={toggleInsert}
            // fade={false}
        >
                    <ModalHeader toggle={toggleInsert}>{t("integr8.common.add.description.text")}</ModalHeader>
                    <ModalBody>
                        <div className="mt-2">
                        <Row>
                        <Col md={3}>
                        <label className="mt-2">{t("integr8.common.description.text")}</label>
                        </Col>
                        <Col md={9}>
                        <Input type="text" name="description"
                               onChange={onDescriptionChange}
                               placeholder={t("integr8.placeholder.text.type.here")} className="next_input mb-2"/>
                        </Col>
                    </Row>
                        </div>
                        <div className="mt-2">
                        <Row>
                        <Col md={3}>
                        <label className="mt-2">{t("integr8.common.unit.text")}</label>
                        </Col>
                            <Col md={9}>
                        <Input type="text" name="unit"
                               onChange={onUnitChange}
                               placeholder={t("integr8.placeholder.text.type.here")}
                               className="next_input mb-2"/>
                            </Col>
                            </Row>
                            </div>
                        <div className="mt-2">
                        <Row>
                        <Col md={3}>
                        <label>{t("program.manager.add.description.fees.unit")}</label>
                        </Col>
                            <Col md={9}>
                        <Input type="number" min={0} name="fee"
                               onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                               onChange={onFeeChange}
                               placeholder={t("integr8.placeholder.text.type.here")} className="next_input mb-2"/>
                            </Col>
                            </Row>
                            </div>
                        <div className="mt-2">
                        <Row>
                        <Col md={3}>
                        <label>{t("program.manager.add.description.select.service")}</label>
                        </Col>
                        <Col md={9}>
                        <Select
                            classNamePrefix="next_select"
                            options={serviceTypeList}
                            onChange={onServiceTypeChange}
                            placeholder={t("integr8.select.placeholder.text")}/>
                        </Col>
                            </Row>
                            </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button className="next_cancel_btn me-3"
                                onClick={toggleInsert}>{t("integr8.common.cancel.text")}</Button>

                        <ButtonWithLoader
                            disabled={disableAddDescriptionModalBtn()}
                            loading={isUpLoaded}
                            text={t('pm.project.add.btn')}
                            onClick={insertSowDescription}
                            className="next_btn next_btn_lg"
                        />

                    </ModalFooter>

                </Modal>


        {/* ==========================================   SOW Description Edit modal ======================================= */}


        <Modal
            isOpen={descriptionEditModal}
            toggle={editSowDescription}
            // className={props.className}
        >
                    <ModalHeader
                        toggle={editSowDescription}>{t("program.manager.add.description.modal.header.description")}</ModalHeader>
                    <ModalBody>
                        <div className="mt-2">
                        <Row>
                        <Col md={3}>
                        <label className="mt-2">{t("program.manager.add.description.modal.body.description")}</label>
                        </Col>
                            <Col md={9}>
                        <Input type="text" name="description"
                               value={updateSowDescription.description}
                               onChange={onSowDescriptionChange}
                               placeholder={t("integr8.placeholder.text.type.here")} className="next_input mb-2"/>
                            </Col>
                        </Row>
                        </div>
                        <div className="mt-2">
                        <Row>
                        <Col md={3}>
                        <label className="mt-2">{t("integr8.common.unit.text")}</label>
                        </Col>
                            <Col md={9}>
                        <Input type="text" name="unit"
                               value={updateSowDescription.unit}
                               onChange={onSowDescriptionChange}
                               placeholder={t("integr8.placeholder.text.type.here")} className="next_input mb-2"/>
                            </Col>
                            </Row>
                            </div>
                        <div className="mt-2">
                        <Row>
                        <Col md={3}>
                        <label>{t("program.manager.add.description.fees.unit")}</label>
                        </Col>
                            <Col md={9}>
                        <Input type="number" min={0}
                               onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                               name="fee"
                               value={updateSowDescription.fee}
                               onChange={onSowDescriptionChange}
                               placeholder={t("integr8.placeholder.text.type.here")}
                               className="next_input mb-2"/>
                            </Col>
                            </Row>
                            </div>
                        <div className="mt-2">
                        <Row>
                        <Col md={3}>
                        <label>{t("program.manager.add.description.select.service")}</label>
                        </Col>
                            <Col md={9}>
                        <Select
                            defaultValue={!_.isEmpty(updateSowDescription.serviceType) ? {
                                label: updateSowDescription.serviceType.name,
                                value: updateSowDescription.serviceType.name
                            } : null}
                            classNamePrefix="next_select"
                            options={serviceTypeList}
                            onChange={onUpdateServiceTypeChange}
                            placeholder={t("integr8.select.placeholder.text")}/>
                            </Col>
                            </Row>
                            </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="next_cancel_btn me-3" onClick={editSowDescription}>
                            {t("integr8.common.close.text")}
                    </Button>
                         <ButtonWithLoader
                             disabled={disableUpdateDescriptionModalBtn()}
                             loading={isUpLoaded}
                             text={t('integr8.common.update.text')}
                             onClick={updateSowDescriptionData}
                             className="next_btn next_btn_lg mr-2"
                         />

                    </ModalFooter>
                </Modal>



            </span>)
}


export default AddDescriptionModal;

