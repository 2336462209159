import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties';

var ProjectController = {


    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findAllProject: function () {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/finance/all/projectlist`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (projectId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/id/${projectId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByUserInfo: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/projectlist/pm/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },
    findBySalesUserInfo: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/projectlist/sales/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },
    findPartnerProjectListByUserInfo: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/projectlist/partner/id/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findProjectList: function () {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/mt/all/projectlist`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    insertProject: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/insert`, {
            method: "post",

            body: JSON.stringify(Project),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
    salesInsertProject: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/sales-insert`, {
            method: "post",

            body: JSON.stringify(Project),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    insertSummary: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/insert/summary`, {
            method: "post",

            body: JSON.stringify(Project),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateProject: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/update`, {
            method: "post",

            body: JSON.stringify(Project),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateProjectName: function(Project) {
        const access_token = ApiUtils.getCookie('accessToken');

        return fetch(`${conf_prop.get('integr8ServiceUrl')}/rest/project/update-project-name`, {
            method: 'post',

            body: JSON.stringify(Project),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json',

            }),
        }).then(ApiUtils.checkStatus);
    },

    uploadProjectImage: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/upload-project-image`, {
            method: "post",
            body: Project,
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,


            })
        }).then(ApiUtils.checkStatus);
    },

    updateProjectValue: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/update-project-value`, {
            method: "post",

            body: JSON.stringify(Project),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateProjectStatusComplete: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/updateStatus-complete`, {
            method: "post",
            body: JSON.stringify(Project),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
    updateProjectStatusLost: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/updateStatus-lost`, {
            method: "post",
            body: JSON.stringify(Project),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
    pmUpdateProjectStage: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/pm-update-project-stage`, {
            method: "post",
            body: JSON.stringify(Project),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
    salesUpdateProjectStage: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/sales-update-project-stage`, {
            method: "post",
            body: JSON.stringify(Project),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    pmUpdateSalesMember: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/pm-update-sales-member`, {
            method: "post",

            body: JSON.stringify(Project),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    salesUpdatePmMember: function (Project) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/sales-update-pm-member`, {
            method: "post",

            body: JSON.stringify(Project),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    findByProjectId: function (projectId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/sow-invoice-pending-action/project/id/${projectId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },
    findPhotoById: function (projectId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/photo/${projectId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkResponse).then(res => res.blob());
    },

    findBillingPipeLineData: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/billing-pipeline/all`, {
            method: "get",
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
            })
        }).then(ApiUtils.checkStatus);
    },

    findSalesPipeLineData: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/sales-pipeline/all`, {
            method: "get",
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
            })
        }).then(ApiUtils.checkStatus);
    },

    findProjectProfitabilityData: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/project-profitability/all`, {
            method: "get",
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
            })
        }).then(ApiUtils.checkStatus);
    },

    findPartnerBillingData: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/project/partner-billing/all`, {
            method: "get",
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
            })
        }).then(ApiUtils.checkStatus);
    },
};

export default ProjectController;

