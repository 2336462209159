import React, {useEffect} from 'react';
import DataTableView from '../../../components/data-table/data-table-view';
import {useTranslation} from 'react-i18next';
import Moment from 'react-moment';
import conf_prop from '../../../properties/properties';


function PmMtSalesSowTab({project, userInfo, initiatedProjectSowList}) {

    const route_path = conf_prop.get('route_path');
    const {t} = useTranslation();
    const columns = [{
        name: 'Partner',
        sortable: true,
        filter: (rowData, value) => rowData.projectAdminPartner.userInfo.name.toLowerCase().includes(value.toLowerCase()),
        selector: rowData => rowData.projectAdminPartner.userInfo.name,
    }, {
        name: 'SOW No',
        sortable: true,
        filter: (rowData, value) => (`SOW${rowData.psowId}`).toString().toLowerCase().includes(value.toLowerCase()),
        selector: rowData => rowData.psowId,
        cell: (rowData) => {
            return (<div>
                SOW{rowData.psowId}
            </div>);
        },

    }, {
        name: 'SOW Issued on',
        sortable: true,
        filter: (rowData, value) => rowData.genDate.toString().toLowerCase().includes(value.toLowerCase()),
        selector: rowData => rowData.genDate,
        cell: (rowData) => <Moment format="DD/MM/YYYY">{rowData.genDate}</Moment>,
    }, {
        name: 'SOW Status',
        sortable: true,
        selector: rowData => rowData.sowStatus,
        filter: (rowData, value) => rowData.sowStatus.toLowerCase().includes(value.toLowerCase()),
        cell: (rowData) => <div>
            {rowData.sowStatus === 'Issued' && <div>{t('program.manager.sow.status.comment.issued.text')}</div>}
            {rowData.sowStatus === 'Initiated' && <div>{t('program.manager.sow.status.comment.initiated.text')}</div>}
            {rowData.sowStatus === 'Approved' && <div>{t('program.manager.sow.status.comment.approved.text')}</div>}
            {rowData.sowStatus === 'Rejected' && <div>{t('program.manager.sow.status.comment.rejected.text')}</div>}
            {rowData.sowStatus === 'Accepted' && <div>{t('program.manager.sow.status.comment.accepted.text')}</div>}
            {rowData.sowStatus === 'Declined' && <div>{t('program.manager.sow.status.comment.declined.text')}</div>}
        </div>,
    }, {
        name: '', center: true, cell: (rowData) =>

            <div className="text-center">

                {userInfo.role.internalName === 'PM' && (<div className="">
                    {rowData.sowStatus === 'Issued' ? (<span>
                                 {project.projectStatus === 'Y' ? (
                                     <a href={'/integr8/program-manager/view-sow/' + rowData.psowId_ENCRYPTED}
                                        className="next_outline_accent1_btn next_btn_lg ">{t('integr8.common.edit.text')}</a>) : (
                                     <a
                                         className="next_outline_accent1_btn next_btn_lg ">{t('integr8.common.edit.text')}</a>)}
                             </span>) : (

                        <a href={`/integr8/program-manager/view-sow/${rowData.psowId_ENCRYPTED}?requested_url=${window.location.href}`}
                           className="btn-info btn-sm">{t('admin.client.view.table.view')}</a>

                    )}
                </div>)}


                {userInfo.role.internalName === 'SALES' && (<div className="">
                    {rowData.sowStatus !== 'Issued' && (<span>
                                 {project.projectStatus === 'Y' ? (
                                     <a href={'/integr8/sales/view-sow/' + rowData.psowId_ENCRYPTED}
                                        className="btn-info btn-sm ">{t('admin.client.view.table.view')}</a>) : (<a
                                     className="next_outline_accent1_btn next_btn_lg ">{t('integr8.common.edit.text')}</a>)}
                             </span>)}
                </div>)}

                {userInfo.role.internalName === 'MT' && (<div className="">
                    {rowData.sowStatus !== 'Issued' && (<span>
            {project.projectStatus === 'Y' ? (<span>
                                               {(rowData.projectAdminMt.userInfo.name === userInfo.name && rowData.sowStatus === 'Initiated') ? (
                                                   <a href={`/integr8/management/view-sow/${rowData.psowId_ENCRYPTED}?extraParam=comingFromInsideProject`}
                                                      className="next_outline_accent1_btn next_btn_lg ">{t('admin.client.view.table.view')}</a>) : (
                                                   <a href={`/integr8/management/view-sow/${rowData.psowId_ENCRYPTED}`}
                                                      className="btn-info btn-sm ">{t('admin.client.view.table.view')}</a>)}
                                             </span>) : (<a
                className="next_outline_accent1_btn next_btn_lg ">{t('integr8.common.edit.text')}</a>)}
          </span>)}
                </div>)}
            </div>,
    },


    ];

    useEffect(() => {

        async function fetchData() {

        }

        fetchData();

    }, []);


    return (<div>
        <div className="feedback_table containt_e2e-datatable mt-3">
            <DataTableView
                title={t('pm.close.projects.title')}
                columns={columns}
                data={userInfo.role.internalName === 'PM' ? _.orderBy(project.projectSows, ['psowId'], ['desc']) : _.orderBy(initiatedProjectSowList, ['psowId'], ['desc'])}
                options={{search: true, extendedSearch: false, pagination: true}}
            />
        </div>
    </div>);
}

export default PmMtSalesSowTab;