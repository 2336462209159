import * as Yup from 'yup';

export const costAnalysisSchema = Yup.object().shape({
    unitCost: Yup.string().required('Unit cost is required'),
    unitCount: Yup.string().required('Unit count is required'),
    totalCost: Yup.number().required('Total cost is required'),
    // comments: Yup.string().trim().required('Comments are required'),
    serviceType: Yup.object().shape({
        stId: Yup.number().required('Service Type ID is required')
    })
});


