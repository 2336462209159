import React, {useEffect, useRef, useState} from 'react';
import Croppie from 'croppie/croppie';
import {Col, Row} from 'reactstrap';
import 'croppie/croppie.css';

import {useTranslation} from 'react-i18next';
import {validateImage} from "image-validator";
import {ButtonWithLoader, toastify} from '../index';

const ImageCroppier = (props) => {
    const { t } = useTranslation();
    const [croppedImage, setCroppedImage] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const fileRef = useRef(null);
    const croppieRef = useRef(null);
    const imgRef = useRef(null);
    const croppieInstance = useRef(null);

    const croppieOptions = {
        showZoomer: true,
        enableOrientation: true,
        mouseWheelZoom: 'ctrl',
        viewport: {
            width: 190,
            height: 190,
            type: 'square',
        },
        boundary: {
            width: '200px',
            height: '200px',
        },
    };

    useEffect(() => {
        croppieInstance.current = new Croppie(croppieRef.current, croppieOptions);
    }, []);

    const onFileUpload = async (e) => {
        const file = fileRef.current.files[0];
        const isValidImage = await validateImage(file);

        const imageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (imageTypes.includes(file.type) && isValidImage) {
            const size = file.size / 1024;

            if (size < 500) {
                const objectURL = URL.createObjectURL(file);
                croppieInstance.current.bind({ url: objectURL });
                setIsFileUploaded(true);
            } else {
                toastify("error", t(`integr8.client.file.upload.size.error`), t(`integr8.client.file.upload.size.error`))
            }
        } else {
            // Handle file type error
            setIsFileUploaded(false);
        }
    };

    const onResult = () => {
        setIsBtnLoading(true);
        croppieInstance.current.result({ type: 'blob', size: 'original', format: 'png', quality: 1 })
            .then((file) => {
                props.uploadProfilePhoto(file);
                //imgRef.current.src = URL.createObjectURL(file);
                setCroppedImage(file);
                setIsBtnLoading(false);
            });
    };

    return (
        <div>
            <div ref={croppieRef}></div>

            <div className="mt-3 text-center ">
                <h6><strong>{t("integr8.croppie.heading")}</strong></h6>
            </div>

            <Row className="mt-5">
                <Col md={6}>
                    <input  type="file"
                            id="files"
                            name="file"
                            className="next_file_input  test_choose_file"
                            ref={fileRef}
                            onChange={onFileUpload}
                            accept=".png,.jpeg,.jpg"
                    />


                </Col>
                <Col md={6}>
                    <div className="text-end">
                        {isBtnLoading ? (
                          <ButtonWithLoader
                            className="next_btn next_btn_lg ms-2"
                            loading={isBtnLoading}
                            disabled={!isFileUploaded}
                            onClick={onResult}
                            text={t('integr8.croppie.upload.btn')}
                          >
                              <i className="mdi mdi-upload">
                              </i>
                          </ButtonWithLoader>
                        ) : (
                          <ButtonWithLoader
                            className="next_btn next_btn_lg ms-2"
                            loading={isBtnLoading}
                            disabled={!isFileUploaded}
                            onClick={onResult}
                            text={t('integr8.croppie.upload.btn')}
                          >
                              <i className="mdi mdi-upload">
                              </i>
                          </ButtonWithLoader>
                        )}
                    </div>
                </Col>
                {/*<Col md={12} style={{display: !croppedImage ? 'none' : 'block'}}>
                    <h2>{t("integr8.croppie.cropped.img")}</h2>
                    <div>
                        <img ref={imgRef} alt="cropped" />
                        <a hidden={!croppedImage} href={croppedImage} download="cropped.png">
                            {t("integr8.croppie.download.cropped.img")}
                        </a>
                    </div>
                </Col>*/}
            </Row>
        </div>
    );
};

export default ImageCroppier;
