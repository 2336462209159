import React, { useState } from "react";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";

import PropTypes from "prop-types";
import _ from "lodash";
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageValidateSize,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
);

// Our app
function UploadLogoFile(props) {
  let { acceptedFileTypes, maxFiles, allowMultiple, handleFileState } = props;
  allowMultiple = _.isBoolean(allowMultiple) ? allowMultiple : false;
  maxFiles = _.isNumber(maxFiles) ? maxFiles : 1;

  const [files, set_files] = useState([]);

  function handleInit() {
    //console.log('FilePond instance has initialised', this.pond);
  }

  return (
    <div className="UploadFile">
      {/* Pass FilePond properties as attributes */}
      <FilePond
        ref={(ref) => (this.pond = ref)}
        files={this.props.files}
        allowMultiple={allowMultiple}
        maxFiles={maxFiles}
        allowFileSizeValidation={true}
        maxFileSize={'512KB'}
        allowImageValidateSize={false}
        imageValidateSizeMaxHeight={60}
        // imageValidateSizeMaxWidth={"4000"}
        allowImageResize={true}
        allowImagePreview={true}
        imageResizeTargetHeight={60}
        // imagePreviewMaxHeight={60}
        allowImageCrop={false}
        imageCropAspectRatio={'4:1'}
        acceptedFileTypes={acceptedFileTypes}
        oninit={() => handleInit()}
        onupdatefiles={(fileItems) => {
          // Set current file objects to this.state
          // this.setState({
          //     files: fileItems.map(fileItem => fileItem.file)
          // });
          const files = fileItems.map((fileItem) => fileItem.file);
          handleFileState(files);
        }}
      ></FilePond>
    </div>
  );
}

UploadLogoFile.propTypes = {
  files: PropTypes.array.isRequired,
  acceptedFileTypes: PropTypes.array.isRequired,
  maxFiles: PropTypes.number.isRequired,
  allowMultiple: PropTypes.bool.isRequired,
  handleFileState: PropTypes.func.isRequired,
};

export default UploadLogoFile;
