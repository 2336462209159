import React, { useEffect, useRef, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PmMtFinanceProjectInvoice
    from '../../../pages/common-components/pm-mt-finance-components/pm-mt-finance-project-invoice';
import img1 from '../../../assets/images/users/project-value.png';
import img2 from '../../../assets/images/users/projected-cost.png';
import img3 from '../../../assets/images/users/amount-invoiced.png';
import img4 from '../../../assets/images/users/paidbyclient.png';
import 'suneditor/dist/css/suneditor.min.css';
import _ from 'lodash';
import BillingInstructionController from '../../../api/controller/billinginstruction-controller';
import ApiUtils from '../../../api/ApiUtils';
import { getSoupText } from '../../../util/text-util';
import PmMtBillingInstructionsTab
    from '../../../pages/common-components/pm-mt-components/pm-mt-billing-instructions-tab';


function PmMtBillingViewTab({ project, userInfo, totalProjectedRevenueCurrentYear, salesProjectAdmin }) {
    let projectAdmin = ApiUtils.getLocalStorage('projectAdmin');
    const [isPostBtnLoading, set_isPostBtnLoading] = useState(false);
    const [isLoaded, set_isLoaded] = useState(false);
    const {t} = useTranslation();
    const [files, set_files] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [billingInstructions, set_billingInstructions] = useState([]);
    const [fileEmpty, set_fileEmpty] = useState('');
    const [fileAttachedStatus, set_fileAttachedStatus] = useState(false);

    const [billingInstruction, set_billingInstruction] = useState({
        billingInstructionText: '', project: {projectId: project.projectId}, projectAdmin: project.projectAdmin,
    });
    const soupText = getSoupText(billingInstruction.billingInstructionText);

    const editorRef = useRef();
   /* const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };

    function onbillingInstructionChange(e) {
        set_billingInstruction(billingInstruction_prev => update(billingInstruction_prev, {'billingInstructionText': {$set: e}}));
    }*/

    function handleChangeTab(activeTabInput) {
        setActiveTab(activeTabInput);
    }


  /*  function resetFile() {
        set_fileAttachedStatus(false);
        set_files([]);
        set_fileEmpty(Date.now());
    }*/

    async function findBillingInstructionsByProject() {
        await BillingInstructionController.findByProject(project.projectId_ENCRYPTED).then(billingInstructions => {
            set_billingInstructions(billingInstructions);
        }).catch((error) => {
            console.error(error);
        });
    }

    function handleFileState(e) {
        set_files(e.target.files);
        set_fileAttachedStatus(true);
        //e.target.value = null;
    }


  /*  function submitBillingInstruction() {

        set_isPostBtnLoading(true);
        if (!_.isEmpty(files)) {
            let formData = new FormData();
            formData.append('billingInstructionJson', JSON.stringify(billingInstruction));
            for (let i = 0; i < files.length; i++) {
                formData.append('uploadedFile', files[i]);
            }
            BillingInstructionController.insertBillingInstructionWithFile(formData).then(result => {

                toastify('success', t('program.manager.billing.view.saved.successfully.msg'), t('program.manager.billing.view.saved.successfully.msg'));
                set_billingInstructions(billingInstructions_prev => billingInstructions_prev.concat(result));
                set_files([]);
                editorRef.current.setContents('');

            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                set_isPostBtnLoading(false);
                findBillingInstructionsByProject();
                resetFile();

            });
        } else {
            BillingInstructionController.insertBillingInstruction(billingInstruction).then(result => {

                toastify('success', t('program.manager.billing.view.saved.successfully.msg'), t('program.manager.billing.view.saved.successfully.msg'));
                set_billingInstructions(billingInstructions_prev => billingInstructions_prev.concat(result));
                editorRef.current.setContents('');

            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                set_isPostBtnLoading(false);
                findBillingInstructionsByProject();

            });
        }

    }*/


    useEffect(() => {
        set_isLoaded(false);

        async function fetchData() {
            set_isLoaded(true);
            await findBillingInstructionsByProject();
        }

        fetchData();

    }, []);

    // console.log('files:', files);
    return (<div>
        <div className="integr8_next_small_card">
            <Row>
                <Col sm={12} md={6} lg={3} className="ps-3 pe-0">
                    <div className="next_card" style={{backgroundColor: '#FAC410', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-2">
                                    <img style={{height: '30px'}}
                                         src={img1}
                                         alt="total-goal"/>
                                </div>
                                <div className="align-self-center ps-3">
                                    <div
                                        className="mt-2 mb-0 integr8_small_card_text">{t('integr8.estimated.project.value')}</div>
                                    {!_.isEmpty(project.projections) ? (
                                        <h6
                                            className="goal_count mt-1 ">₹{_.sumBy(project.projections, 'projectionAmount').toLocaleString('en-IN')}
                                        </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={6} lg={3} className="ps-3 pe-0">
                    <div className="next_card" style={{backgroundColor: '#26C6DA', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                             src={img2}
                                                                             alt="ontrack-goal"/></div>
                                <div className="align-self-center ps-3">
                                    <div
                                        className="mt-2 mb-0 integr8_small_card_text">{t('program.manager.project.actual.rev.heading.total.projected.revenue')}</div>
                                    {!_.isEmpty(project.projections) ? (
                                        <h6 className="goal_count mt-1 ">₹{totalProjectedRevenueCurrentYear.toLocaleString('en-IN')}
                                        </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={6} lg={3} className="ps-3 pe-0">
                    <div className="next_card" style={{backgroundColor: '#8961FF', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                             src={img3}
                                                                             alt="delayed-goal"/></div>
                                <div className="align-self-center ps-3">
                                    <div
                                        className="mt-2 mb-0 integr8_small_card_text">{t('integr8.amount.invoiced.text')}</div>
                                    {!_.isEmpty(project.projectInvoices) ? (
                                        <h6
                                            className="goal_count mt-1 ">₹{_.sumBy(project.projectInvoices, 'invoiceAmount').toLocaleString('en-IN')}
                                        </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={6} lg={3} className="ps-3 pe-3">
                    <div className="next_card" style={{backgroundColor: '#4AC18E', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                             src={img4}
                                                                             alt="close-goal"/></div>
                                <div className="align-self-center ps-3">
                                    <div
                                        className="mt-2 mb-0 integr8_small_card_text">{t('integr8.paid.by.client.text')}</div>
                                    {!_.isEmpty(project.projectInvoices) ? (
                                        <h6
                                            className="goal_count mt-1">₹{_.sumBy(_.filter(project.projectInvoices, {'paymentStatus': 'Y'}), 'invoiceAmount').toLocaleString('en-IN')}</h6>) : (
                                        <h6 className="goal_count mt-1">₹0</h6>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
        </div>


        {/*<div className="mt-3 mb-3 tw-font-bold">
            {t('program.manager.billing.view.heading.billing')}</div>
        {!_.isEmpty(billingInstructions) && (<div className="mt-3"
                                                  style={{height: billingInstructions.length <= 3 ? (billingInstructions.length * 100) + 'px' : (400 + 'px')}}>
            <PerfectScrollbar className="">
                {billingInstructions.map((billingInstruction, index) => (<div>
                        <Row className="mb-2">
                            {!_.isEmpty(userInfo.mimeType) ?
                                <Col lg="1" md="1" sm="12" xs="12" className="pe-0">
                                    <UpdatePhoto
                                        userInfo={userInfo}/>
                                </Col>
                                : <Col lg="1" md="1" sm="12" xs="12" className="pe-0">
                                    <img className="round" alt={"user-photo"}
                                         src="https://cdn.thinktalentws48.click/image/user_default.png"/>
                                </Col>
                            }
                            <Col lg="10" md="10" sm="11" xs="11" className="ps-0">
                                <div className="mt-1">
                                    <div className="font-14">
                                        {billingInstruction.projectAdmin.userInfo.name}
                                    </div>
                                    <div style={{fontSize: '12px'}}>
                                        <Moment format="DD/MM/YYYY hh:ss a">{billingInstruction.genDate}</Moment>
                                    </div>
                                </div>
                            </Col>
                        </Row>


                        <div className="mb-1 comment-section">
                            {!_.isEmpty(billingInstruction) ? (<div className={'comment'}
                             dangerouslySetInnerHTML={{__html: billingInstruction.billingInstructionText}}/>) : ""}
                        </div>
                        <div>
                            {!_.isNull(billingInstruction.fileName) &&
                                <div className="font-13 fw-bold pb-3" style={{color: '#37A4FE', marginTop: '-10px'}}>
                                    <a
                                        href={conf_prop.get('integr8ServiceUrl') + '/rest/billinginstruction/billing-download/' + billingInstruction.biId_ENCRYPTED}>
                                        <i className="ti-download me-2" style={{color: '#37A4FE'}}></i>
                                        {t('finance.billing.inst.download.file')}
                                    </a>
                                </div>}

                        </div>
                    </div>

                ))}
            </PerfectScrollbar>
        </div>)}

        <div>
            {userInfo.role.internalName === 'PM' ? (<div>
                <div>
                    {project.projectStatus === 'Y' ? (<div>
                        <div className="mt-2">
                            <SunEditor
                                placeholder={t(`common.component.proj.clnt.cont.type.here`)}
                                name="pageDesc"
                                setOptions={{
                                    ...sunEditorExtraOptions,
                                    mode: 'classic',
                                    height: '150px',
                                    videoWidth: '100%',
                                    videoHeight: '100%',
                                    resizingBar: true,
                                    videoHeightShow: false,
                                    videoRatioShow: false,
                                    videoResizing: false,
                                    showPathLabel: false,
                                    videoSizeOnlyPercentage: false,
                                    imageFileInput: false,
                                    'buttonList': [basicSunEditorListTableOptions],
                                }}
                                autoFocus={false}
                                // hideToolbar={true}
                                onChange={onbillingInstructionChange}
                                getSunEditorInstance={getSunEditorInstance}

                                setDefaultStyle="font-family: Poppins , sans-serif; font-size: 14px;"
                            />
                        </div>

                        <Row className="mt-3">
                            <Col md={2} className={'pe-0'}>
                                <div className={'integr8_file_input mt-1'}>
                                    <label className={'text-info'} htmlFor="fileUpload"
                                           style={{cursor: 'pointer'}}><i
                                        className="fas fa-paperclip me-1"></i>{t('integr8.common.attach.file.btn')}
                                    </label>

                                    <input type="file"
                                           id="fileUpload"
                                           className="next_file_input mt-4"
                                        // files={files}
                                           onChange={handleFileState}
                                           key={fileEmpty || ''}
                                    />
                                </div>
                            </Col>
                            <Col md={8} className={'ps-0'}>
                                <div className={'mt-1'} style={{marginLeft: '-3rem'}}>
                                    {files.length > 0 && (
                                        <span
                                            onClick={resetFile}>{files[0]?.name}<i
                                            className="fas fa-trash-alt ms-2"/> </span>
                                    )}
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="text-end">
                                    <ButtonWithLoader
                                        className="next_btn next_btn_lg "
                                        disabled={_.isEmpty(soupText)}
                                        loading={isPostBtnLoading}
                                        onClick={submitBillingInstruction}
                                        text={t('integr8.post.btn')}
                                    >
                                    </ButtonWithLoader>
                                </div>
                            </Col>
                        </Row>

                    </div>) : (<span></span>)}
                </div>

            </div>) : (<div></div>)}
        </div>

*/}
        {/*<hr className={'containt_e2e-datatable'}/>*/}


        <div className="next_tabs next_client_tabs modal_containt_e2e">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classNames({active: activeTab === 1})}
                        onClick={() => {
                            handleChangeTab(1);
                        }}
                    >
                        Billing Instructions
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink

                        className={classNames({active: activeTab === 2})}
                        onClick={() => {
                            handleChangeTab(2);
                        }}
                    >
                        {t('program.manager.project.actual.rev.nav.project.invoice')}
                    </NavLink>
                </NavItem>
            </Nav>
        </div>

        <TabContent activeTab={activeTab}>

            <TabPane tabId={1}>
                <PmMtBillingInstructionsTab project={project} userInfo={userInfo}
                                            projectInvoices={project.projectInvoices}
                                            salesProjectAdmin={salesProjectAdmin}
                />
            </TabPane>
            <TabPane tabId={2}>
                <PmMtFinanceProjectInvoice project={project} userInfo={userInfo}
                                           projectInvoices={project.projectInvoices}
                                           salesProjectAdmin={salesProjectAdmin}
                />
            </TabPane>


        </TabContent>

    </div>);
}

export default PmMtBillingViewTab;