import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { NextMaterialTable } from '../../../components/material table';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoaderSpinner from '../../../components/utils/loader-spinner';


function SalesMtSalesPipelineTab({ salesPipeLineData }) {
  const { t } = useTranslation();
  const [isLoaded, set_isLoaded] = useState(false);
  const [instructionModal, setInstructionModal] = useState(false);

  function instructionToggle() {
    setInstructionModal((instructionToggle_prev) => {
      return !instructionToggle_prev;
    });
  }

  const columns = useMemo(() => [{
    header: t('mt.sales.pipeline.tab2.column.client.name.text'),
    id: 'clientName',
    accessorFn: (row) => !_.isEmpty(row.clientName) ? row.clientName : '-',
    size: 180,
    enableResizing: false,
    Cell: ({ row }) => {
      const rowData = row.original;
      return (<div className="text_ellipsis" title={rowData.clientName}>
        {!_.isEmpty(rowData.clientName) ? rowData.clientName : '-'}
      </div>);
    },
  }, {
    header: t('mt.sales.pipeline.tab2.column.project.text'),
    id: 'projectName',
    accessorFn: (row) => row.projectName,
    size: 220,
    enableResizing: false,
    Cell: ({ row }) => {
      const rowData = row.original;
      return (<div className="text_ellipsis" title={rowData.projectName}>
        {!_.isEmpty(rowData.projectName) ? rowData.projectName : '-'}
      </div>);
    },
  }, {
    header: t('mt.sales.pipeline.tab2.column.sales.member.text'),
    id: 'saleMember',
    accessorFn: (row) => !_.isEmpty(row.saleMember) ? row.saleMember : '-',
    size: 180,
    enableResizing: false,
    Cell: ({ row }) => {
      const rowData = row.original;
      return (<div className="text_ellipsis" title={rowData.saleMember}>
        {!_.isEmpty(rowData.saleMember) ? rowData.saleMember : '-'}
      </div>);
    },
  }, {
    header: t('mt.sales.pipeline.tab2.column.project.manager.text'),
    id: 'projectManager',
    accessorFn: (row) => !_.isEmpty(row.projectManager) ? row.projectManager : '-',
    size: 180,
    enableResizing: false,
    Cell: ({ row }) => {
      const rowData = row.original;
      return (<div className="text_ellipsis" title={rowData.projectManager}>
        {!_.isEmpty(rowData.projectManager) ? rowData.projectManager : '-'}
      </div>);
    },
  }, {
    header: t('mt.sales.pipeline.tab2.column.industry.text'),
    id: 'industryName',
    accessorFn: (row) => !_.isEmpty(row.industryName) ? row.industryName : '-',
    size: 140,
    enableResizing: false,
    Cell: ({ row }) => {
      const rowData = row.original;
      return (<div className="text_ellipsis" title={rowData.industryName}>
        {!_.isEmpty(rowData.industryName) ? rowData.industryName : '-'}
      </div>);
    },
  }, {
    header: t('mt.sales.pipeline.tab2.column.solution.text'),
    id: 'solutionName',
    accessorFn: (row) => !_.isEmpty(row.solutionName) ? row.solutionName : '-',
    size: 180,
    enableResizing: false,
    Cell: ({ row }) => {
      const rowData = row.original;
      return (<div className="text_ellipsis" title={rowData.solutionName}>
        {!_.isEmpty(rowData.solutionName) ? rowData.solutionName : '-'}
      </div>);
    },
  }, {
    header: t('mt.sales.pipeline.tab2.column.status.text'),
    id: 'projectStatus',
    accessorFn: (row) => !_.isEmpty(row.projectStatus) ? row.projectStatus : '-',
    size: 130,
    enableResizing: false,
  }, {
    header: t('mt.sales.pipeline.tab2.column.project.value.text'),
    id: 'projectValue',
    accessorFn: (row) => !_.isNull(row.projectValue) ? row.projectValue.toLocaleString('en-IN') : '0',
    size: 140,
    enableResizing: false,
    Cell: ({ row }) => {
      const rowData = row.original;
      return (<span>
            {!_.isNull(rowData.projectValue) ? rowData.projectValue.toLocaleString('en-IN') : '0'}
          </span>);
    },
  }]);

  // const columns = [{
  //     name: t("mt.sales.pipeline.tab2.column.client.name.text"), sortable: true, // maxWidth: "20%",
  //     selector: rowData => rowData.clientName, // filter: (rowData, value) => rowData.projectName.toString().toLowerCase().includes(value.toLowerCase()),
  // },
  //
  //     {
  //         name: t("mt.sales.pipeline.tab2.column.project.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.projectName, // filter: (rowData, value) => rowData.clientName.toString().toLowerCase().includes(value.toLowerCase()),
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab2.column.sales.member.text"), sortable: true, // maxWidth: "7%",
  //         selector: rowData => rowData.saleMember, cell: rowData => <span>
  //                 {!_.isEmpty(rowData.saleMember) ? rowData.saleMember : "-"}
  //             </span>,
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab2.column.project.manager.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.projectManager, cell: rowData => <span>
  //                 {!_.isEmpty(rowData.projectManager) ? rowData.projectManager : "-"}
  //             </span>,
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab2.column.industry.text"), sortable: true, // maxWidth: "19%",
  //         //center: true,
  //         selector: rowData => rowData.industryName, cell: rowData => <span>
  //                 {!_.isEmpty(rowData.industryName) ? rowData.industryName : "-"}
  //             </span>,
  //     }, {
  //         name: t("mt.sales.pipeline.tab2.column.solution.text"), sortable: true, // maxWidth: "7%",
  //         selector: rowData => rowData.solutionName, cell: rowData => <span>
  //                 {!_.isEmpty(rowData.solutionName) ? rowData.solutionName : "-"}
  //             </span>,
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab2.column.status.text"), sortable: true, // maxWidth: "10%",
  //         selector: rowData => rowData.projectStatus,
  //         cell: rowData => <span>
  //                 {!_.isEmpty(rowData.projectStatus) ? rowData.projectStatus : "-"}
  //             </span>,
  //
  //     }, {
  //         name: t("mt.sales.pipeline.tab2.column.project.value.text"), sortable: true, // maxWidth: "19%",
  //         //center: true,
  //         selector: rowData => rowData.projectValue, cell: rowData => <span>
  //                 {!_.isNull(rowData.projectValue) ? rowData.projectValue.toLocaleString('en-IN') : "0"}
  //             </span>,
  //     }];

  useEffect(() => {
    async function fetchData() {

      set_isLoaded(true);
    }

    fetchData();
  }, []);

  if (!isLoaded) {


    return (<Card className="text-center">
      <CardBody>
        <LoaderSpinner />
      </CardBody>
    </Card>);
  } else {

    return (<>
      <div className="ms-2" style={{ fontSize: '13px' }}>
          <span style={{ color: '#333' }}>
    {t(`integr8.dashboard.all.the.data.represented.instruction`)}
    <br />
            {/*<i className="fa fa-info-circle" aria-hidden="true" style={{ color: '#007bff', marginRight: '8px' }}></i>*/}
            <a href="#" onClick={instructionToggle} style={{ textDecoration: 'underline', color: '#007bff' }}>
      {t(`integr8.dashboard.click.here.to.learn.instruction`)}
    </a>
  </span>
      </div>
      <div className="table_hover">
        <NextMaterialTable
          columns={columns}
          data={_.orderBy(salesPipeLineData, ['genDate'], ['desc'])}
          options={{
            enableColumnResizing: true, initialState: {
              columnOrder: ['clientName', 'projectName', 'saleMember', 'projectManager', 'industryName', 'solutionName', 'projectStatus', 'projectValue'],
              pagination: { pageSize: 100, pageIndex: 0 },
            }, // enableRowSelection: true,
            // onRowSelectionChange: ({ rowSelectionState }) => console.log(rowSelectionState),
            state: {
              showLoadingOverlay: !isLoaded, showSkeletons: !isLoaded,
            },
            muiPaginationProps: {
              rowsPerPageOptions: [100, 200, 300, 400, 500],
              showRowsPerPage: true,
            },
          }}
        />
      </div>
      {/*==========================================Instruction Modal=================================================*/}

      <Modal
        isOpen={instructionModal}
        toggle={instructionToggle}
        className="modal-lg"
        backdrop="static"
      >
        <ModalHeader toggle={instructionToggle}>{t(`integr8.sales.pipeline.modal.heading`)}</ModalHeader>
        <ModalBody>
          <div>
            <div className={'instruction-body'}>
              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.sales.pipeline.modal.instruction.title1`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.sales.pipeline.modal.instruction.content1`)}</span>
              </section>

              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.sales.pipeline.modal.instruction.title2`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.sales.pipeline.modal.instruction.content2`)}</span>
              </section>
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
          <Button className="integr8_cancel_btn"
                  onClick={instructionToggle}>{t(`integr8.close.btn.text`)}</Button>

        </ModalFooter>

      </Modal>

    </>);

  }
}

export default SalesMtSalesPipelineTab;
