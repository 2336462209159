import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

var ProjectStatusStageController = {


        findAll: function () {
            const access_token = ApiUtils.getCookie("accessToken");

            return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectStatusStage/all`, {
                method: "get",

                headers: new Headers({
                    Authorization: `Bearer ${access_token}`,

                })
            }).then(ApiUtils.checkStatus);
        },
    }
;

export default ProjectStatusStageController;
