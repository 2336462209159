import React, {lazy} from 'react';
import Loadable from '../layouts/loader/Loadable';
import conf_prop from '../properties/properties';
import {Navigate} from 'react-router-dom';
import PmMtSinglePartnerProfileView from '../views/pm-module/individual-project/pm-mt-single-partner-profile-view';
import GoToOldIntegr8 from '../pages/program-manager/go-to-old-integr8';
import PmMtSalesManageContacts from '../pages/common-components/pm-mt-sales-components/pm-mt-sales-manage-contacts';
import MtSalesFinanceDashboard
    from '../pages/common-components/mt-sales-dashboard-components/mt-sales-finance-dashboard';


const ProjectsView = Loadable(lazy(() => import('../pages/sales/projects')));
const IndividualProjectView = Loadable(lazy(() => import('../pages/common-components/pm-mt-components/pm-mt-individual-project')));
const PmMtFinancePartnerProfile = Loadable(lazy(() => import('../pages/program-manager/pm-mt-finance-partner-profile')));
const MyApprovals = Loadable(lazy(() => import('../pages/program-manager/my-approvals')));
const PmMtDocumentRepository = Loadable(lazy(() => import('../pages/program-manager/document-repository')));
const ClientListView = Loadable(lazy(() => import('../pages/program-manager/client-list')));
const PmMtSalesSowIssue = Loadable(lazy(() => import('../pages/program-manager/pm-mt-sales-sow-issue')));
const PmMtPartnerProfileView = Loadable(lazy(() => import('../views/pm-module/individual-project/pm-mt-single-partner-profile-view')));
const PmMtPartnerViewInvoice = Loadable(lazy(() => import('../pages/common-components/pm-mt-partner-finance-components/pm-mt-partner-finance-view-invoice')));
const UpdatesView = Loadable(lazy(() => import('../pages/common-components/user-updates')));
const PmMtFinanceMyProfile = Loadable(lazy(() => import('../pages/common-components/pm-mt-finance-components/pm-mt-finance-my-profile')));
const ManageContacts = Loadable(lazy(() => import('../pages/common-components/pm-mt-sales-components/pm-mt-sales-manage-contacts')));
const route_path = conf_prop.get('route_path');

const SalesRoutes = [


    {
        path: `${route_path}/sales/projects`, name: 'Projects', exact: true, element: <ProjectsView/>,
    },

    {
        path: `${route_path}/sales/individual-project/:projectId_ENCRYPTED`,
        name: 'individual-project',
        exact: true,
        element: <IndividualProjectView/>,
    },

    {
        path: `${route_path}/sales/partner-profile`,
        name: 'Partner Profiles',
        exact: true,
        element: <PmMtFinancePartnerProfile/>,
    },

    {
        path: `${route_path}/sales/partner-single-profile/:userId_ENCRYPTED`,
        name: 'view-profile',
        exact: true,
        element: <PmMtSinglePartnerProfileView/>,
    },
    {
        path: `${route_path}/sales/manage-contacts`,
        name: 'Manage Contacts',
        exact: true,
        element: <PmMtSalesManageContacts/>,
    },

    {
        path: `${route_path}/sales/approvals`, name: 'My Approvals', exact: true, element: <MyApprovals/>,
    },

    {
        path: `${route_path}/sales/document-repository`,
        name: 'Standard Documents',
        exact: true,
        element: <PmMtDocumentRepository/>,
    },

    {
        path: `${route_path}/sales/client-list`, name: 'Client List', exact: true, element: <ClientListView/>,
    },

    {
        path: `${route_path}/sales/my-profile`,
        name: 'My Profile',
        exact: true,
        element: <PmMtFinanceMyProfile/>,
    },

    {
        path: `${route_path}/sales/view-sow/:psowId_ENCRYPTED`,
        name: 'View Issue',
        exact: true,
      element: <PmMtSalesSowIssue />,
    },

    {
        path: `${route_path}/sales/view-invoice/:sowiId_ENCRYPTED`,
        name: 'Show Invoice',
        exact: true,
        element: <PmMtPartnerViewInvoice/>,
    },

    {
        path: `${route_path}/sales/updates`, name: 'Updates', exact: true, element: <UpdatesView/>,
    },

    {
        path: `${route_path}/sales/oldintegr8`,
        name: 'Go to Old Integr8',
        exact: true,
        element: <GoToOldIntegr8/>,
    },
    {
        path: `${route_path}/sales/dashboard`,
        name: 'Dashboard',
        exact: true,
        element: <MtSalesFinanceDashboard/>,
    },

    {
        path: '*', element: <Navigate to="/auth/404"/>
    },

];
export default SalesRoutes;
